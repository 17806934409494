import { useState } from 'react';

import { type Scan, useSearchAllScansInfoLazyQuery } from '@graphql/generated';
import { reportAllPagesResults } from '@helpers/reportAllPagesResults';
import { NON_UNIFY, UNIFIED, UNIFY } from '@pages/Search/constans';
import { UNKNOWN } from '@pages/Search/MultiSelect';

interface Result {
  scans: Scan[];
  getScans: () => Promise<void>;
  loading: boolean;
}

export const useAllScansQuery = (
  siteScanId: string,
  selectedConfidence: string[],
  selectedStatus: string[],
  selectedUnify: string[],
  selectedOriginal: string[],
  selectedFixed: string[],
  selectedPlatformType: string[]
): Result => {
  const [getData] = useSearchAllScansInfoLazyQuery();
  const [scans, setScans] = useState<Scan[]>([]);
  const [loading, setLoading] = useState(false);

  const getDataByFilters = async () => {
    setScans([]);

    if (
      !siteScanId ||
      selectedConfidence.length === 0 ||
      selectedStatus.length === 0 ||
      selectedUnify.length === 0 ||
      selectedOriginal.length === 0 ||
      selectedFixed.length === 0 ||
      selectedPlatformType.length === 0
    )
      return;

    setLoading(true);

    const allScans: Scan[] = [];

    await reportAllPagesResults(
      getData,
      (res) => {
        const filteredItems =
          res?.data?.searchScans?.items?.filter((item) => item?.analyzed_entities?.items?.length) ||
          [];

        const filterScans = filteredItems
          .map((item) => {
            return {
              ...item,
              analyzed_entities: {
                ...item?.analyzed_entities,
                items: item?.analyzed_entities?.items?.filter((entity) => {
                  if (!entity) return false;

                  const isUnified =
                    (selectedUnify.includes(UNIFY) && item?.is_unified) ||
                    (selectedUnify.includes(NON_UNIFY) && item?.is_unified === false);

                  const isStatus = selectedStatus.includes((entity?.status ?? '').toUpperCase());

                  const isConfidence = selectedConfidence.includes(
                    (entity?.confidence || '').toUpperCase()
                  );

                  const isPlatformType =
                    (selectedPlatformType.includes(UNKNOWN) && !item?.platform_type) ||
                    selectedPlatformType.includes(item?.platform_type || '');

                  const isOriginal = selectedOriginal.includes(
                    entity?.pipesqa_edit_is_fixed ? 'true' : 'false'
                  );

                  const isFixed = selectedFixed.includes(
                    entity?.pipesqa_edit_is_fixed ? 'true' : 'false'
                  );

                  return (
                    isUnified && isStatus && isConfidence && isPlatformType && isOriginal && isFixed
                  );
                })
              }
            };
          })
          .filter((s) => s.analyzed_entities.items?.length);

        allScans.push(...(filterScans as Scan[]));
      },
      (res) => res.data?.searchScans?.nextToken,
      { siteScanId }
    ).finally(() => {
      setScans(allScans);
      setLoading(false);
    });
  };

  return {
    scans,
    getScans: getDataByFilters,
    loading
  };
};
