import { useState, useEffect, Fragment } from 'react';
import { Marker, useMap } from 'react-leaflet';
import L, { type LatLngExpression } from 'leaflet';

import GeoJsonWithUpdates from '../GeoJsonWithUpdates';
import { useEditPipeStore } from '@store';

import type { CoordinateGeoJsonObject } from 'src/types/geojson';
import { distanceToLineSegment, formatCoordinate } from './EditPipe.utils';
import type { EditPoint, HistoryAction, LineData } from './EditPipe.types';

const EDIT_LINE_COLOR = '#ffb6c9';

type Props = {
  readonly utilityData: CoordinateGeoJsonObject;
};

export const EditPipe = ({ utilityData }: Props) => {
  const map = useMap();
  const { lines, setLines } = useEditPipeStore();

  const [history, setHistory] = useState<HistoryAction[]>([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(-1);
  const [isDraggingLine, setIsDraggingLine] = useState(false);
  const [dragStartPoint, setDragStartPoint] = useState<L.LatLng | null>(null);
  const [activeLineId, setActiveLineId] = useState<string | null>(null);
  const [isKeyPressed, setIsKeyPressed] = useState(false);

  // Initializer
  useEffect(() => {
    if (utilityData?.type === 'LineString') {
      const coordinates = utilityData.coordinates as number[][];

      const initialMarkers = coordinates.map((coord, index) => ({
        lon: formatCoordinate(coord[0]),
        lat: formatCoordinate(coord[1]),
        index,
        lineId: 'initial'
      }));

      const initialState = [
        {
          id: 'initial',
          originalPipeId: utilityData.originalPipeId,
          data: utilityData,
          markers: initialMarkers
        }
      ];

      setLines(initialState);
      setHistory([]);
      setCurrentHistoryIndex(-1);
    }
  }, [utilityData]);

  const addToHistory = (action: HistoryAction) => {
    setHistory((prevHistory) => {
      const newHistory = prevHistory.slice(0, currentHistoryIndex + 1);

      const updatedHistory = [...newHistory, action];

      setCurrentHistoryIndex(updatedHistory.length - 1);

      return updatedHistory;
    });
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'KeyD') {
        setIsKeyPressed(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.code === 'KeyD') {
        setIsKeyPressed(false);
        setIsDraggingLine(false);
        setDragStartPoint(null);
        setActiveLineId(null);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleLineDragStart = (lineId: string) => (e: L.LeafletMouseEvent) => {
    if (!isKeyPressed) return;

    e.originalEvent.preventDefault();
    setIsDraggingLine(true);
    setDragStartPoint(e.latlng);
    setActiveLineId(lineId);

    map.dragging.disable();
  };

  const handleLineDragMove = (e: L.LeafletMouseEvent) => {
    if (!isDraggingLine || !dragStartPoint || !activeLineId) return;

    const currentPoint = e.latlng;
    const latDiff = currentPoint?.lat - dragStartPoint?.lat;
    const lngDiff = currentPoint?.lng - dragStartPoint?.lng;

    const newState = lines.map((line) => {
      if (line.id !== activeLineId) return line;

      const newMarkers = line.markers.map((marker) => ({
        ...marker,
        lat: formatCoordinate(marker?.lat + latDiff),
        lon: formatCoordinate(marker?.lon + lngDiff)
      }));

      const newCoordinates = newMarkers.map((marker) => [marker?.lon, marker?.lat]);

      return {
        ...line,
        markers: newMarkers,
        data: {
          ...line.data,
          coordinates: newCoordinates
        }
      };
    });

    setLines(newState);
    setDragStartPoint(currentPoint);
  };

  const handleLineDragEnd = () => {
    if (!isDraggingLine || !activeLineId) return;

    const previousState = [...lines];
    addToHistory({
      type: 'LINE_DRAG',
      lineId: activeLineId,
      previousState,
      newState: lines
    });

    setIsDraggingLine(false);
    setDragStartPoint(null);
    setActiveLineId(null);

    map.dragging.enable();
  };

  useEffect(() => {
    if (isDraggingLine) {
      map.on('mousemove', handleLineDragMove);
      map.on('mouseup', handleLineDragEnd);

      return () => {
        map.off('mousemove', handleLineDragMove);
        map.off('mouseup', handleLineDragEnd);
      };
    }
  }, [isDraggingLine, dragStartPoint, activeLineId]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'z') {
        e.preventDefault();

        if (e.shiftKey) {
          // Ctrl/Cmd + Shift + Z: Redo
          handleRedo();
        } else {
          // Ctrl/Cmd + Z: Undo
          handleUndo();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentHistoryIndex, history]);

  const handleUndo = () => {
    if (currentHistoryIndex >= 0) {
      const action = history[currentHistoryIndex];

      setLines(action.previousState);
      setCurrentHistoryIndex((prev) => prev - 1);
    }
  };

  const handleRedo = () => {
    if (currentHistoryIndex < history.length - 1) {
      const action = history[currentHistoryIndex + 1];

      setLines(action.newState);
      setCurrentHistoryIndex((prev) => prev + 1);
    }
  };

  const handleMarkerDragEnd = (lineId: string, markerIndex: number, lat: number, lon: number) => {
    const previousState = [...lines];

    const newState = lines.map((line) => {
      if (line.id !== lineId) return line;

      const newMarkers = line.markers.map((marker, i) =>
        i === markerIndex
          ? { ...marker, lat: formatCoordinate(lat), lon: formatCoordinate(lon) }
          : marker
      );

      const newCoordinates = newMarkers.map((marker) => [marker?.lon, marker?.lat]);

      return {
        ...line,
        markers: newMarkers,
        data: {
          ...line.data,
          coordinates: newCoordinates
        }
      };
    });

    setLines(newState);
    addToHistory({
      type: 'MARKER_MOVE',
      lineId,
      previousState,
      newState
    });
  };

  const handleMarkerDelete = (lineId: string, markerIndex: number) => {
    const previousState = [...lines];
    const lineIndex = lines.findIndex((l) => l.id === lineId);

    if (lineIndex === -1) return;

    const line = lines[lineIndex];
    const { markers } = line;

    let newState: LineData[];

    if (markers.length === 2) {
      newState = lines.filter((_, i) => i !== lineIndex);
    } else {
      const newMarkers = [...markers.slice(0, markerIndex), ...markers.slice(markerIndex + 1)].map(
        (m, i) => ({ ...m, index: i })
      );

      const newCoordinates = newMarkers.map((marker) => [marker?.lon, marker?.lat]);

      newState = lines.map((l, i) => {
        if (i !== lineIndex) return l;
        return {
          ...l,
          markers: newMarkers,
          data: {
            ...l.data,
            coordinates: newCoordinates
          }
        };
      });
    }

    setLines(newState);
    addToHistory({
      type: 'MARKER_DELETE',
      lineId,
      previousState,
      newState
    });
  };

  const handleLineSegmentDelete = (lineId: string, segmentIndex: number) => {
    const previousState = [...lines];
    const lineIndex = lines.findIndex((l) => l.id === lineId);
    if (lineIndex === -1) return;

    const line = lines[lineIndex];
    const { markers } = line;

    let newState: LineData[];
    // If there's only one segment (2 markers), delete the entire line
    if (markers.length === 2) {
      newState = lines.filter((_, i) => i !== lineIndex);
    } else if (segmentIndex === 0 || segmentIndex === markers.length - 2) {
      // If it's the first or last segment, remove that segment
      const newMarkers =
        segmentIndex === 0
          ? markers.slice(1).map((m, i) => ({ ...m, index: i }))
          : markers.slice(0, -1).map((m, i) => ({ ...m, index: i }));

      const newCoordinates = newMarkers.map((marker) => [marker?.lon, marker?.lat]);

      newState = lines.map((l, i) => {
        if (i !== lineIndex) return l;
        return {
          ...l,
          markers: newMarkers,
          data: {
            ...l.data,
            coordinates: newCoordinates
          }
        };
      });
    } else {
      // If it's a middle segment, split the line into two
      const firstLineId = `${lineId}-1`;
      const secondLineId = `${lineId}-2`;

      // First line: from start to segment start
      const firstLineMarkers = markers
        .slice(0, segmentIndex + 1)
        .map((m, i) => ({ ...m, index: i, lineId: firstLineId }));

      // Second line: from segment end to end
      const secondLineMarkers = markers
        .slice(segmentIndex + 1)
        .map((m, i) => ({ ...m, index: i, lineId: secondLineId }));

      const newLines: LineData[] = [];

      if (firstLineMarkers.length > 1) {
        newLines.push({
          id: firstLineId,
          data: {
            ...line.data,
            type: 'LineString' as const,
            coordinates: firstLineMarkers.map((marker) => [marker?.lon, marker?.lat])
          },
          markers: firstLineMarkers
        });
      }

      if (secondLineMarkers.length > 1) {
        newLines.push({
          id: secondLineId,
          data: {
            ...line.data,
            type: 'LineString' as const,
            coordinates: secondLineMarkers.map((marker) => [marker?.lon, marker?.lat])
          },
          markers: secondLineMarkers
        });
      }

      newState = [...lines.slice(0, lineIndex), ...newLines, ...lines.slice(lineIndex + 1)];
    }

    setLines(newState);
    addToHistory({
      type: 'SEGMENT_DELETE',
      lineId,
      previousState,
      newState
    });
  };

  const handleLineContextMenu = (lineId: string) => (e: L.LeafletMouseEvent) => {
    e.originalEvent.preventDefault();

    const line = lines.find((l) => l.id === lineId);
    if (!line) return;

    const clickPoint = e.latlng;
    let minDistance = Infinity;
    let segmentToDelete = 0;

    // Find the closest line segment to the click
    for (let i = 0; i < line.markers.length - 1; i++) {
      const start = L.latLng(line.markers[i]?.lat, line.markers[i]?.lon);
      const end = L.latLng(line.markers[i + 1]?.lat, line.markers[i + 1]?.lon);

      const distance = distanceToLineSegment(clickPoint, start, end);

      if (distance < minDistance) {
        minDistance = distance;
        segmentToDelete = i;
      }
    }

    handleLineSegmentDelete(lineId, segmentToDelete);
  };

  const handleLineClick = (lineId: string) => (e: L.LeafletMouseEvent) => {
    if (e.originalEvent.button !== 0) return;

    if (e.originalEvent.shiftKey) {
      const line = lines.find((l) => l.id === lineId);

      if (!line || line.markers.length <= 2) return;

      handleStraightenLine(lineId);

      return;
    }

    const previousState = [...lines];
    const newState = lines.map((line) => {
      if (line.id !== lineId) return line;

      // Get click coordinates
      const clickPoint = e.latlng;

      // Find the closest segment of the line
      let minDistance = Infinity;
      let insertIndex = 0;

      for (let i = 0; i < line.markers.length - 1; i++) {
        const start = L.latLng(line.markers[i]?.lat, line.markers[i]?.lon);
        const end = L.latLng(line.markers[i + 1]?.lat, line.markers[i + 1]?.lon);

        const distance = distanceToLineSegment(clickPoint, start, end);

        if (distance < minDistance) {
          minDistance = distance;
          insertIndex = i + 1;
        }
      }

      const newMarkers = [
        ...line.markers.slice(0, insertIndex),
        {
          lat: formatCoordinate(clickPoint?.lat),
          lon: formatCoordinate(clickPoint?.lng),
          index: insertIndex,
          lineId
        },
        ...line.markers.slice(insertIndex).map((m: EditPoint) => ({
          ...m,
          index: m.index + 1
        }))
      ];

      const newCoordinates = newMarkers.map((marker: EditPoint) => [marker?.lon, marker?.lat]);

      return {
        ...line,
        markers: newMarkers,
        data: {
          ...line.data,
          coordinates: newCoordinates
        }
      };
    });

    setLines(newState);
    addToHistory({
      type: 'ADD_POINT',
      lineId,
      previousState,
      newState
    });
  };

  const handleStraightenLine = (lineId: string) => {
    const previousState = [...lines];
    const lineIndex = lines.findIndex((l) => l.id === lineId);

    if (lineIndex === -1) return;

    const line = lines[lineIndex];
    const { markers } = line;

    const newMarkers = [
      { ...markers[0], index: 0 },
      { ...markers[markers.length - 1], index: 1 }
    ];

    const newCoordinates = newMarkers.map((marker) => [marker?.lon, marker?.lat]);

    const newState = lines.map((l, i) => {
      if (i !== lineIndex) return l;
      return {
        ...l,
        markers: newMarkers,
        data: {
          ...l.data,
          coordinates: newCoordinates
        }
      };
    });

    setLines(newState);
    addToHistory({
      type: 'STRAIGHTEN_LINE',
      lineId,
      previousState,
      newState
    });
  };

  return (
    <>
      {lines.map((line) => (
        <Fragment key={line.id}>
          <GeoJsonWithUpdates
            data={line.data}
            style={{ color: EDIT_LINE_COLOR }}
            eventHandlers={{
              click: handleLineClick(line.id),
              contextmenu: handleLineContextMenu(line.id),
              mousedown: handleLineDragStart(line.id)
            }}
          />
          {line.markers.map(({ lat, lon, index }) => {
            if (!lat || !lon) return null;
            const position: LatLngExpression = [lat, lon];
            const size = 12;

            const icon = L.divIcon({
              className: '',
              html: `<div style="width: ${size}px; height: ${size}px; background-color: white; border: 2px solid #666; border-radius: 50%; cursor: ${
                isKeyPressed ? 'move' : 'pointer'
              }"></div>`,
              iconSize: [size, size]
            });

            return (
              <Marker
                key={`${line.id}-${index}`}
                position={position}
                icon={icon}
                draggable
                eventHandlers={{
                  dragend: (e) => {
                    const marker = e.target;
                    const position = marker.getLatLng();

                    handleMarkerDragEnd(line.id, index, position?.lat, position?.lng);
                  },
                  contextmenu: (e) => {
                    e.originalEvent.preventDefault();

                    handleMarkerDelete(line.id, index);
                  }
                }}
              />
            );
          })}
        </Fragment>
      ))}
    </>
  );
};
