import { Polygon } from './Polygon/types';
import * as turf from '@turf/turf';

export const calculateEndpoint = (
  lat: number,
  lng: number,
  distance: number,
  direction: 'left' | 'right',
  angle: number
) => {
  const earthRadius = 6378.1; // Radius of the Earth in km
  const directionFactor = direction === 'right' ? 1 : -1;
  const brng = angle * directionFactor * (Math.PI / 180); // Convert angle to radians with direction

  const latRad = lat * (Math.PI / 180); // Convert latitude to radians
  const lngRad = lng * (Math.PI / 180); // Convert longitude to radians

  const endLat = Math.asin(
    Math.sin(latRad) * Math.cos(distance / earthRadius) +
      Math.cos(latRad) * Math.sin(distance / earthRadius) * Math.cos(brng)
  );
  const endLng =
    lngRad +
    Math.atan2(
      Math.sin(brng) * Math.sin(distance / earthRadius) * Math.cos(latRad),
      Math.cos(distance / earthRadius) - Math.sin(latRad) * Math.sin(endLat)
    );

  return { lat: endLat * (180 / Math.PI), lng: endLng * (180 / Math.PI) }; // Convert back to degrees
};

function closePolygon(polygonPoints: Polygon) {
  // If the first and last points aren't the same, add the first point to the end
  if (
    polygonPoints.length > 0 &&
    (polygonPoints[0][0] !== polygonPoints[polygonPoints.length - 1][0] ||
      polygonPoints[0][1] !== polygonPoints[polygonPoints.length - 1][1])
  ) {
    polygonPoints.push(polygonPoints[0]);
  }
  return polygonPoints;
}

export const isPointInPolygon = (polygonPoints: Polygon, point: [number, number]) => {
  const closedPolygon = closePolygon([...polygonPoints]); // Close the polygon points array
  const polygon = turf.polygon([closedPolygon]);
  const pt = turf.point(point);

  return turf.booleanPointInPolygon(pt, polygon);
};

export enum zIndexes {
  TILELAYER = 10,
  ORTHOPHOTO = 20,
  INTERPOLATION = 30,
  SCAN_PATH = 40,
  OTHER_UTILITIES = 50,
  UTILITY = 60,
  TRANSMITTER_LIST = 70,
  DEPTH_LIST = 80,
  EDIT_PIPE = 90,
  PIPE_CROSSINGS = 100,
  Y_PEAKS = 110,
  ZOOMHANDLER = 120,
  ACTIVE_CROSSING_POINT = 130
}
