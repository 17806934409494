export const presetComments = [
  {
    id: 12,
    text: 'Edge of scan →',
    next: [
      { id: 14, text: 'Most likely placed outside of the scan' },
      { id: 15, text: 'Most likely placed inside the scan' }
    ]
  },
  { id: 13, text: 'Transmitter Glare' },
  { id: 1, text: 'The pipe is straight' },
  { id: 16, text: 'Suspected as two close pipes' },
  {
    id: 2,
    text: `The pipe continues →`,
    next: [
      { id: 3, text: 'north' },
      { id: 4, text: 'south' },
      { id: 5, text: 'east' },
      { id: 6, text: 'west' }
    ]
  },
  {
    id: 7,
    text: 'The pipe curves →',
    next: [
      { id: 8, text: 'north' },
      { id: 9, text: 'south' },
      { id: 10, text: 'east' },
      { id: 11, text: 'west' }
    ]
  }
];
