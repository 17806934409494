import React from 'react';
import { GeoJSONOptions } from 'leaflet';

import GeoJsonWithUpdates from '../GeoJsonWithUpdates';

import { CoordinateGeoJsonObject } from 'src/types/geojson';
import { currentUtilityColor } from '@constants/colors';
import { CURRENTUTILITYWIDTH } from '@pages/Main/MapView/constants';

interface Props {
  utilityData?: CoordinateGeoJsonObject;
}

const styles: GeoJSONOptions['style'] = {
  color: currentUtilityColor,
  weight: CURRENTUTILITYWIDTH
};

const Utility: React.FC<Props> = ({ utilityData }) => {
  if (!utilityData) return null;

  return <GeoJsonWithUpdates data={utilityData} style={styles} />;
};

export default Utility;
