import styled from 'styled-components';
import Button from '@components/Button';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
`;

export const NameContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const IDTitle = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
`;

export const Divider = styled.div`
  height: 16px;
  background: rgba(0, 0, 0, 0.3);
  width: 0.5px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleText = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

export const SubTitleText = styled.sub`
  font-weight: 400;
  vertical-align: sub;
  font-size: smaller;
`;

export const SliderText = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  padding: 2px 20px;
`;

export const EditModeButton = styled(StyledButton)<{ editmode: string }>`
  background-color: ${({ editmode }) => (editmode === 'true' ? '#4A90E2' : 'inherit')};
  color: ${({ editmode }) => (editmode === 'true' ? 'white' : 'inherit')};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;
