import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import React, { useContext } from 'react';
import { StatusesDiv, NullDiv, CountLabel } from './styles';

const Statuses = () => {
  const { statuses, analyzedEntityList, emptyAnalyzedEntitiesIdList, currentIndex } =
    useContext(AnalyzedEntityContext);

  return (
    <div>
      <StatusesDiv>
        <CountLabel>Validated: </CountLabel>
        {statuses.approved + statuses.rejected}/{analyzedEntityList.length}
        {emptyAnalyzedEntitiesIdList.length > 0 && (
          <NullDiv> (+{emptyAnalyzedEntitiesIdList.length} missing) </NullDiv>
        )}
      </StatusesDiv>
      <StatusesDiv>
        <CountLabel>Current pipe view: </CountLabel>
        {currentIndex + 1}/{analyzedEntityList.length}
      </StatusesDiv>
    </div>
  );
};

export default Statuses;
