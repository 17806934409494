import { Marker } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';

import { useCrossingViewStore } from '@store';

const ActiveCrossingPoint = () => {
  const crossingViewPositionIndex = useCrossingViewStore(
    (state) => state.crossingViewPositionIndex
  );
  const crossingCoordinates = useCrossingViewStore((state) => state.crossingCoordinates);
  const crossingViewIndex = useCrossingViewStore((state) => state.crossingViewIndex);

  if (!crossingCoordinates?.length || crossingViewIndex === undefined || !crossingViewPositionIndex)
    return null;

  const currentPoint = crossingCoordinates?.[crossingViewIndex]?.[crossingViewPositionIndex];

  if (!currentPoint || !Array.isArray(currentPoint) || currentPoint?.length < 2) {
    return null;
  }

  const position: LatLngExpression = [currentPoint[1], currentPoint[0]];
  const size = 15;

  const icon = L.divIcon({
    className: '',
    html: `<div style="width: ${size}px; height: ${size}px; background-color: #FFC0CB; border-radius: 50%; display: flex; align-items: center; justify-content: center; color: white; font-size: ${12}px;"></div>`,
    iconSize: [size, size]
  });

  return <Marker position={position} icon={icon} />;
};

export default ActiveCrossingPoint;
