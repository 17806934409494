import { useEditPipeStore } from '@store';
import { Button, ButtonTypes } from '@components/Button/styles';

import EditModeInstructions from './EditModeInstructions';
import { useCreateFixAnalyzedEntityTaskMutation } from '@graphql/generated';

const EditPipeModeActions = () => {
  const { setEditMode, lines } = useEditPipeStore();
  const [createFixAnalyzedEntityTask] = useCreateFixAnalyzedEntityTaskMutation();

  const onSave = async () => {
    try {
      for (const line of lines) {
        await createFixAnalyzedEntityTask({
          variables: {
            createFixAnalyzedEntityTaskInput: {
              new_location: JSON.stringify(line.data.coordinates),
              analyzedEntityFix_pipes_tasksId: line.data.originalPipeId ?? ''
            }
          }
        });
      }

      setEditMode(false);
    } catch (error) {
      setEditMode(false);

      alert(`Error saving tasks: ${error}`);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '15px',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        padding: '12px',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
      <EditModeInstructions />
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          padding: '0 20px 0 0'
        }}>
        <Button onClick={onSave} type={ButtonTypes.approve}>
          Save
        </Button>
        <Button onClick={() => setEditMode(false)} type={ButtonTypes.reject}>
          Discard
        </Button>
      </span>
    </div>
  );
};

export default EditPipeModeActions;
