import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDate: { input: any; output: any; }
  AWSDateTime: { input: any; output: any; }
  AWSEmail: { input: any; output: any; }
  AWSIPAddress: { input: any; output: any; }
  AWSJSON: { input: any; output: any; }
  AWSPhone: { input: any; output: any; }
  AWSTime: { input: any; output: any; }
  AWSTimestamp: { input: any; output: any; }
  AWSURL: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Double: { input: any; output: any; }
};

export enum Algorithm {
  CorrelationFactor = 'CORRELATION_FACTOR',
  MeanCrossingAngle = 'MEAN_CROSSING_ANGLE',
  MeanCrossingPower = 'MEAN_CROSSING_POWER',
  NCrossings = 'N_CROSSINGS'
}

export type AnalyzedEntity = {
  __typename?: 'AnalyzedEntity';
  algorithm?: Maybe<Algorithm>;
  analysis_confidence?: Maybe<Confidence>;
  analysis_edge_of_scan?: Maybe<Scalars['Boolean']['output']>;
  analyzedEntityFixed_pipesId?: Maybe<Scalars['ID']['output']>;
  auto_edge_of_scan?: Maybe<Scalars['Boolean']['output']>;
  changed_by?: Maybe<Scalars['String']['output']>;
  changes?: Maybe<ModelChangeConnection>;
  comments?: Maybe<ModelCommentConnection>;
  confidence?: Maybe<Confidence>;
  createdAt: Scalars['AWSDateTime']['output'];
  crossings?: Maybe<ModelCrossingConnection>;
  depth_result_per_algorithm?: Maybe<Scalars['String']['output']>;
  exoanalyzer_flagged?: Maybe<Scalars['Boolean']['output']>;
  fix_pipes_tasks?: Maybe<ModelFixAnalyzedEntityTaskConnection>;
  fixed_pipes?: Maybe<ModelAnalyzedEntityConnection>;
  flagged?: Maybe<Scalars['Boolean']['output']>;
  frequency?: Maybe<Scalars['Float']['output']>;
  geometry?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mean_angle_crossings?: Maybe<Scalars['Float']['output']>;
  n_crossings?: Maybe<Scalars['Int']['output']>;
  original_pipe?: Maybe<AnalyzedEntity>;
  pipe_crossings?: Maybe<Scalars['String']['output']>;
  pipe_depths?: Maybe<Scalars['String']['output']>;
  pipesqa_edit_is_fixed?: Maybe<Scalars['Boolean']['output']>;
  pipesqa_edit_is_original?: Maybe<Scalars['Boolean']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  probability?: Maybe<Scalars['Float']['output']>;
  scan?: Maybe<Scan>;
  scanAnalyzed_entitiesId?: Maybe<Scalars['ID']['output']>;
  scanFrequencyAnalyzed_entitiesId?: Maybe<Scalars['ID']['output']>;
  scan_frequency?: Maybe<ScanFrequency>;
  site?: Maybe<Site>;
  siteAnalyzed_entitiesId?: Maybe<Scalars['ID']['output']>;
  snr?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Status>;
  updatedAt: Scalars['AWSDateTime']['output'];
  z_peaks_depth_algo_view?: Maybe<Scalars['String']['output']>;
};


export type AnalyzedEntityChangesArgs = {
  filter?: InputMaybe<ModelChangeFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type AnalyzedEntityCommentsArgs = {
  filter?: InputMaybe<ModelCommentFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type AnalyzedEntityCrossingsArgs = {
  filter?: InputMaybe<ModelCrossingFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type AnalyzedEntityFix_Pipes_TasksArgs = {
  filter?: InputMaybe<ModelFixAnalyzedEntityTaskFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type AnalyzedEntityFixed_PipesArgs = {
  filter?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type Change = {
  __typename?: 'Change';
  analyzedEntityChangesId?: Maybe<Scalars['ID']['output']>;
  analyzed_entity?: Maybe<AnalyzedEntity>;
  change?: Maybe<Scalars['String']['output']>;
  changed_by?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  timestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type Comment = {
  __typename?: 'Comment';
  analyzedEntityCommentsId?: Maybe<Scalars['ID']['output']>;
  analyzed_entity?: Maybe<AnalyzedEntity>;
  changed_by: Scalars['String']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export enum Confidence {
  High = 'HIGH',
  Highest = 'HIGHEST',
  Low = 'LOW',
  Lowest = 'LOWEST',
  Medium = 'MEDIUM',
  None = 'NONE',
  NoPipe = 'NO_PIPE'
}

export type CreateAnalyzedEntityInput = {
  algorithm?: InputMaybe<Algorithm>;
  analysis_confidence?: InputMaybe<Confidence>;
  analysis_edge_of_scan?: InputMaybe<Scalars['Boolean']['input']>;
  analyzedEntityFixed_pipesId?: InputMaybe<Scalars['ID']['input']>;
  auto_edge_of_scan?: InputMaybe<Scalars['Boolean']['input']>;
  changed_by?: InputMaybe<Scalars['String']['input']>;
  confidence?: InputMaybe<Confidence>;
  depth_result_per_algorithm?: InputMaybe<Scalars['String']['input']>;
  exoanalyzer_flagged?: InputMaybe<Scalars['Boolean']['input']>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  frequency?: InputMaybe<Scalars['Float']['input']>;
  geometry?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  mean_angle_crossings?: InputMaybe<Scalars['Float']['input']>;
  n_crossings?: InputMaybe<Scalars['Int']['input']>;
  pipe_crossings?: InputMaybe<Scalars['String']['input']>;
  pipe_depths?: InputMaybe<Scalars['String']['input']>;
  pipesqa_edit_is_fixed?: InputMaybe<Scalars['Boolean']['input']>;
  pipesqa_edit_is_original?: InputMaybe<Scalars['Boolean']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  probability?: InputMaybe<Scalars['Float']['input']>;
  scanAnalyzed_entitiesId?: InputMaybe<Scalars['ID']['input']>;
  scanFrequencyAnalyzed_entitiesId?: InputMaybe<Scalars['ID']['input']>;
  siteAnalyzed_entitiesId?: InputMaybe<Scalars['ID']['input']>;
  snr?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Status>;
  z_peaks_depth_algo_view?: InputMaybe<Scalars['String']['input']>;
};

export type CreateChangeInput = {
  analyzedEntityChangesId?: InputMaybe<Scalars['ID']['input']>;
  change?: InputMaybe<Scalars['String']['input']>;
  changed_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  timestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type CreateCommentInput = {
  analyzedEntityCommentsId?: InputMaybe<Scalars['ID']['input']>;
  changed_by: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type CreateCrossingInput = {
  analyzedEntityCrossingsId?: InputMaybe<Scalars['ID']['input']>;
  checked?: InputMaybe<Array<InputMaybe<Status>>>;
  crossing_type: Scalars['String']['input'];
  crossing_url: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateFixAnalyzedEntityTaskInput = {
  analyzedEntityFix_pipes_tasksId?: InputMaybe<Scalars['ID']['input']>;
  app_name?: InputMaybe<Scalars['String']['input']>;
  app_version?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  new_location?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInterpolationInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  interpolation_type: Scalars['String']['input'];
  interpolation_url: Scalars['String']['input'];
  scanFrequencyInterpolationsId?: InputMaybe<Scalars['ID']['input']>;
  scanInterpolationsId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateScanFrequencyInput = {
  frequency: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  scanScan_frequenciesId?: InputMaybe<Scalars['ID']['input']>;
  y_peaks_view?: InputMaybe<Scalars['String']['input']>;
};

export type CreateScanInput = {
  country: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  is_source_of_unified?: InputMaybe<Scalars['Boolean']['input']>;
  is_unified?: InputMaybe<Scalars['Boolean']['input']>;
  orthophoto_layer_url?: InputMaybe<Scalars['String']['input']>;
  platform_type?: InputMaybe<Scalars['String']['input']>;
  scanUnified_wrapperId?: InputMaybe<Scalars['ID']['input']>;
  scan_date: Scalars['AWSDate']['input'];
  scan_name: Scalars['String']['input'];
  scan_path: Scalars['String']['input'];
  scan_path_fix_mode_view?: InputMaybe<Scalars['String']['input']>;
  scan_path_url?: InputMaybe<Scalars['String']['input']>;
  sensor?: InputMaybe<Scalars['String']['input']>;
  siteScanId?: InputMaybe<Scalars['ID']['input']>;
  site_name: Scalars['String']['input'];
  timestamp_utc?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSiteInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type CreateTransmitterInput = {
  frequency: Scalars['Float']['input'];
  geometry?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  scanFrequencyTransmittersId?: InputMaybe<Scalars['ID']['input']>;
  snr?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateUnifiedScanInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  unifiedScanBase_scanId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateUnifiedScansConnectionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  scanId: Scalars['ID']['input'];
  unifiedScanId: Scalars['ID']['input'];
};

export type Crossing = {
  __typename?: 'Crossing';
  analyzedEntityCrossingsId?: Maybe<Scalars['ID']['output']>;
  analyzed_entity: AnalyzedEntity;
  checked?: Maybe<Array<Maybe<Status>>>;
  createdAt: Scalars['AWSDateTime']['output'];
  crossing_type: Scalars['String']['output'];
  crossing_url: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type DeleteAnalyzedEntityInput = {
  id: Scalars['ID']['input'];
};

export type DeleteChangeInput = {
  id: Scalars['ID']['input'];
};

export type DeleteCommentInput = {
  id: Scalars['ID']['input'];
};

export type DeleteCrossingInput = {
  id: Scalars['ID']['input'];
};

export type DeleteFixAnalyzedEntityTaskInput = {
  id: Scalars['ID']['input'];
};

export type DeleteInterpolationInput = {
  id: Scalars['ID']['input'];
};

export type DeleteScanFrequencyInput = {
  id: Scalars['ID']['input'];
};

export type DeleteScanInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSiteInput = {
  id: Scalars['ID']['input'];
};

export type DeleteTransmitterInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUnifiedScanInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUnifiedScansConnectionInput = {
  id: Scalars['ID']['input'];
};

export type FixAnalyzedEntityTask = {
  __typename?: 'FixAnalyzedEntityTask';
  analyzedEntityFix_pipes_tasksId?: Maybe<Scalars['ID']['output']>;
  app_name?: Maybe<Scalars['String']['output']>;
  app_version?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  new_location?: Maybe<Scalars['String']['output']>;
  original_pipe?: Maybe<AnalyzedEntity>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
  user?: Maybe<Scalars['String']['output']>;
};

export type Interpolation = {
  __typename?: 'Interpolation';
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  interpolation_type: Scalars['String']['output'];
  interpolation_url: Scalars['String']['output'];
  scan: Scan;
  scanFrequencyInterpolationsId?: Maybe<Scalars['ID']['output']>;
  scanInterpolationsId?: Maybe<Scalars['ID']['output']>;
  scan_frequency?: Maybe<ScanFrequency>;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type ModelAlgorithmInput = {
  eq?: InputMaybe<Algorithm>;
  ne?: InputMaybe<Algorithm>;
};

export type ModelAnalyzedEntityConditionInput = {
  algorithm?: InputMaybe<ModelAlgorithmInput>;
  analysis_confidence?: InputMaybe<ModelConfidenceInput>;
  analysis_edge_of_scan?: InputMaybe<ModelBooleanInput>;
  analyzedEntityFixed_pipesId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAnalyzedEntityConditionInput>>>;
  auto_edge_of_scan?: InputMaybe<ModelBooleanInput>;
  changed_by?: InputMaybe<ModelStringInput>;
  confidence?: InputMaybe<ModelConfidenceInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  depth_result_per_algorithm?: InputMaybe<ModelStringInput>;
  exoanalyzer_flagged?: InputMaybe<ModelBooleanInput>;
  flagged?: InputMaybe<ModelBooleanInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  geometry?: InputMaybe<ModelStringInput>;
  mean_angle_crossings?: InputMaybe<ModelFloatInput>;
  n_crossings?: InputMaybe<ModelIntInput>;
  not?: InputMaybe<ModelAnalyzedEntityConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAnalyzedEntityConditionInput>>>;
  pipe_crossings?: InputMaybe<ModelStringInput>;
  pipe_depths?: InputMaybe<ModelStringInput>;
  pipesqa_edit_is_fixed?: InputMaybe<ModelBooleanInput>;
  pipesqa_edit_is_original?: InputMaybe<ModelBooleanInput>;
  prefix?: InputMaybe<ModelStringInput>;
  probability?: InputMaybe<ModelFloatInput>;
  scanAnalyzed_entitiesId?: InputMaybe<ModelIdInput>;
  scanFrequencyAnalyzed_entitiesId?: InputMaybe<ModelIdInput>;
  siteAnalyzed_entitiesId?: InputMaybe<ModelIdInput>;
  snr?: InputMaybe<ModelFloatInput>;
  status?: InputMaybe<ModelStatusInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  z_peaks_depth_algo_view?: InputMaybe<ModelStringInput>;
};

export type ModelAnalyzedEntityConnection = {
  __typename?: 'ModelAnalyzedEntityConnection';
  items: Array<Maybe<AnalyzedEntity>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelAnalyzedEntityFilterInput = {
  algorithm?: InputMaybe<ModelAlgorithmInput>;
  analysis_confidence?: InputMaybe<ModelConfidenceInput>;
  analysis_edge_of_scan?: InputMaybe<ModelBooleanInput>;
  analyzedEntityFixed_pipesId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAnalyzedEntityFilterInput>>>;
  auto_edge_of_scan?: InputMaybe<ModelBooleanInput>;
  changed_by?: InputMaybe<ModelStringInput>;
  confidence?: InputMaybe<ModelConfidenceInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  depth_result_per_algorithm?: InputMaybe<ModelStringInput>;
  exoanalyzer_flagged?: InputMaybe<ModelBooleanInput>;
  flagged?: InputMaybe<ModelBooleanInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  geometry?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  mean_angle_crossings?: InputMaybe<ModelFloatInput>;
  n_crossings?: InputMaybe<ModelIntInput>;
  not?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAnalyzedEntityFilterInput>>>;
  pipe_crossings?: InputMaybe<ModelStringInput>;
  pipe_depths?: InputMaybe<ModelStringInput>;
  pipesqa_edit_is_fixed?: InputMaybe<ModelBooleanInput>;
  pipesqa_edit_is_original?: InputMaybe<ModelBooleanInput>;
  prefix?: InputMaybe<ModelStringInput>;
  probability?: InputMaybe<ModelFloatInput>;
  scanAnalyzed_entitiesId?: InputMaybe<ModelIdInput>;
  scanFrequencyAnalyzed_entitiesId?: InputMaybe<ModelIdInput>;
  siteAnalyzed_entitiesId?: InputMaybe<ModelIdInput>;
  snr?: InputMaybe<ModelFloatInput>;
  status?: InputMaybe<ModelStatusInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  z_peaks_depth_algo_view?: InputMaybe<ModelStringInput>;
};

export enum ModelAttributeTypes {
  Null = '_null',
  Binary = 'binary',
  BinarySet = 'binarySet',
  Bool = 'bool',
  List = 'list',
  Map = 'map',
  Number = 'number',
  NumberSet = 'numberSet',
  String = 'string',
  StringSet = 'stringSet'
}

export type ModelBooleanInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModelChangeConditionInput = {
  analyzedEntityChangesId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelChangeConditionInput>>>;
  change?: InputMaybe<ModelStringInput>;
  changed_by?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelChangeConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelChangeConditionInput>>>;
  timestamp?: InputMaybe<ModelIntInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelChangeConnection = {
  __typename?: 'ModelChangeConnection';
  items: Array<Maybe<Change>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelChangeFilterInput = {
  analyzedEntityChangesId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelChangeFilterInput>>>;
  change?: InputMaybe<ModelStringInput>;
  changed_by?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelChangeFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelChangeFilterInput>>>;
  timestamp?: InputMaybe<ModelIntInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelCommentConditionInput = {
  analyzedEntityCommentsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelCommentConditionInput>>>;
  changed_by?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelCommentConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCommentConditionInput>>>;
  text?: InputMaybe<ModelStringInput>;
  timestamp?: InputMaybe<ModelIntInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelCommentConnection = {
  __typename?: 'ModelCommentConnection';
  items: Array<Maybe<Comment>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelCommentFilterInput = {
  analyzedEntityCommentsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelCommentFilterInput>>>;
  changed_by?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelCommentFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCommentFilterInput>>>;
  text?: InputMaybe<ModelStringInput>;
  timestamp?: InputMaybe<ModelIntInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelConfidenceInput = {
  eq?: InputMaybe<Confidence>;
  ne?: InputMaybe<Confidence>;
};

export type ModelCrossingConditionInput = {
  analyzedEntityCrossingsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelCrossingConditionInput>>>;
  checked?: InputMaybe<ModelStatusListInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  crossing_type?: InputMaybe<ModelStringInput>;
  crossing_url?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelCrossingConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCrossingConditionInput>>>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelCrossingConnection = {
  __typename?: 'ModelCrossingConnection';
  items: Array<Maybe<Crossing>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelCrossingFilterInput = {
  analyzedEntityCrossingsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelCrossingFilterInput>>>;
  checked?: InputMaybe<ModelStatusListInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  crossing_type?: InputMaybe<ModelStringInput>;
  crossing_url?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelCrossingFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCrossingFilterInput>>>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelFixAnalyzedEntityTaskConditionInput = {
  analyzedEntityFix_pipes_tasksId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelFixAnalyzedEntityTaskConditionInput>>>;
  app_name?: InputMaybe<ModelStringInput>;
  app_version?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  new_location?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelFixAnalyzedEntityTaskConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelFixAnalyzedEntityTaskConditionInput>>>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelFixAnalyzedEntityTaskConnection = {
  __typename?: 'ModelFixAnalyzedEntityTaskConnection';
  items: Array<Maybe<FixAnalyzedEntityTask>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelFixAnalyzedEntityTaskFilterInput = {
  analyzedEntityFix_pipes_tasksId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelFixAnalyzedEntityTaskFilterInput>>>;
  app_name?: InputMaybe<ModelStringInput>;
  app_version?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  new_location?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelFixAnalyzedEntityTaskFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelFixAnalyzedEntityTaskFilterInput>>>;
  status?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelFloatInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  ge?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  le?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
};

export type ModelIdInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['ID']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  ge?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  le?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelIntInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
};

export type ModelInterpolationConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelInterpolationConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  interpolation_type?: InputMaybe<ModelStringInput>;
  interpolation_url?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelInterpolationConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelInterpolationConditionInput>>>;
  scanFrequencyInterpolationsId?: InputMaybe<ModelIdInput>;
  scanInterpolationsId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelInterpolationConnection = {
  __typename?: 'ModelInterpolationConnection';
  items: Array<Maybe<Interpolation>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelInterpolationFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelInterpolationFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  interpolation_type?: InputMaybe<ModelStringInput>;
  interpolation_url?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelInterpolationFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelInterpolationFilterInput>>>;
  scanFrequencyInterpolationsId?: InputMaybe<ModelIdInput>;
  scanInterpolationsId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelScanConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanConditionInput>>>;
  country?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  is_source_of_unified?: InputMaybe<ModelBooleanInput>;
  is_unified?: InputMaybe<ModelBooleanInput>;
  not?: InputMaybe<ModelScanConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanConditionInput>>>;
  orthophoto_layer_url?: InputMaybe<ModelStringInput>;
  platform_type?: InputMaybe<ModelStringInput>;
  scanUnified_wrapperId?: InputMaybe<ModelIdInput>;
  scan_date?: InputMaybe<ModelStringInput>;
  scan_name?: InputMaybe<ModelStringInput>;
  scan_path?: InputMaybe<ModelStringInput>;
  scan_path_fix_mode_view?: InputMaybe<ModelStringInput>;
  scan_path_url?: InputMaybe<ModelStringInput>;
  sensor?: InputMaybe<ModelStringInput>;
  siteScanId?: InputMaybe<ModelIdInput>;
  site_name?: InputMaybe<ModelStringInput>;
  timestamp_utc?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelScanConnection = {
  __typename?: 'ModelScanConnection';
  items: Array<Maybe<Scan>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelScanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanFilterInput>>>;
  country?: InputMaybe<ModelStringInput>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  is_source_of_unified?: InputMaybe<ModelBooleanInput>;
  is_unified?: InputMaybe<ModelBooleanInput>;
  not?: InputMaybe<ModelScanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanFilterInput>>>;
  orthophoto_layer_url?: InputMaybe<ModelStringInput>;
  platform_type?: InputMaybe<ModelStringInput>;
  scanUnified_wrapperId?: InputMaybe<ModelIdInput>;
  scan_date?: InputMaybe<ModelStringInput>;
  scan_name?: InputMaybe<ModelStringInput>;
  scan_path?: InputMaybe<ModelStringInput>;
  scan_path_fix_mode_view?: InputMaybe<ModelStringInput>;
  scan_path_url?: InputMaybe<ModelStringInput>;
  sensor?: InputMaybe<ModelStringInput>;
  siteScanId?: InputMaybe<ModelIdInput>;
  site_name?: InputMaybe<ModelStringInput>;
  timestamp_utc?: InputMaybe<ModelStringInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelScanFrequencyConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanFrequencyConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  not?: InputMaybe<ModelScanFrequencyConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanFrequencyConditionInput>>>;
  scanScan_frequenciesId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  y_peaks_view?: InputMaybe<ModelStringInput>;
};

export type ModelScanFrequencyConnection = {
  __typename?: 'ModelScanFrequencyConnection';
  items: Array<Maybe<ScanFrequency>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelScanFrequencyFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanFrequencyFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelScanFrequencyFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanFrequencyFilterInput>>>;
  scanScan_frequenciesId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
  y_peaks_view?: InputMaybe<ModelStringInput>;
};

export type ModelSiteConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSiteConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelSiteConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSiteConditionInput>>>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelSiteConnection = {
  __typename?: 'ModelSiteConnection';
  items: Array<Maybe<Site>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelSiteFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSiteFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelSiteFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSiteFilterInput>>>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelSizeInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ModelStatusInput = {
  eq?: InputMaybe<Status>;
  ne?: InputMaybe<Status>;
};

export type ModelStatusListInput = {
  contains?: InputMaybe<Status>;
  eq?: InputMaybe<Array<InputMaybe<Status>>>;
  ne?: InputMaybe<Array<InputMaybe<Status>>>;
  notContains?: InputMaybe<Status>;
};

export type ModelStringInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ge?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  le?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelSubscriptionAnalyzedEntityFilterInput = {
  algorithm?: InputMaybe<ModelSubscriptionStringInput>;
  analysis_confidence?: InputMaybe<ModelSubscriptionStringInput>;
  analysis_edge_of_scan?: InputMaybe<ModelSubscriptionBooleanInput>;
  analyzedEntityChangesId?: InputMaybe<ModelSubscriptionIdInput>;
  analyzedEntityCommentsId?: InputMaybe<ModelSubscriptionIdInput>;
  analyzedEntityCrossingsId?: InputMaybe<ModelSubscriptionIdInput>;
  analyzedEntityFix_pipes_tasksId?: InputMaybe<ModelSubscriptionIdInput>;
  analyzedEntityFixed_pipesId?: InputMaybe<ModelSubscriptionIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>>>;
  auto_edge_of_scan?: InputMaybe<ModelSubscriptionBooleanInput>;
  changed_by?: InputMaybe<ModelSubscriptionStringInput>;
  confidence?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  depth_result_per_algorithm?: InputMaybe<ModelSubscriptionStringInput>;
  exoanalyzer_flagged?: InputMaybe<ModelSubscriptionBooleanInput>;
  flagged?: InputMaybe<ModelSubscriptionBooleanInput>;
  frequency?: InputMaybe<ModelSubscriptionFloatInput>;
  geometry?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  mean_angle_crossings?: InputMaybe<ModelSubscriptionFloatInput>;
  n_crossings?: InputMaybe<ModelSubscriptionIntInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>>>;
  pipe_crossings?: InputMaybe<ModelSubscriptionStringInput>;
  pipe_depths?: InputMaybe<ModelSubscriptionStringInput>;
  pipesqa_edit_is_fixed?: InputMaybe<ModelSubscriptionBooleanInput>;
  pipesqa_edit_is_original?: InputMaybe<ModelSubscriptionBooleanInput>;
  prefix?: InputMaybe<ModelSubscriptionStringInput>;
  probability?: InputMaybe<ModelSubscriptionFloatInput>;
  snr?: InputMaybe<ModelSubscriptionFloatInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  z_peaks_depth_algo_view?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionBooleanInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModelSubscriptionChangeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionChangeFilterInput>>>;
  change?: InputMaybe<ModelSubscriptionStringInput>;
  changed_by?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionChangeFilterInput>>>;
  timestamp?: InputMaybe<ModelSubscriptionIntInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionCommentFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCommentFilterInput>>>;
  changed_by?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCommentFilterInput>>>;
  text?: InputMaybe<ModelSubscriptionStringInput>;
  timestamp?: InputMaybe<ModelSubscriptionIntInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionCrossingFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCrossingFilterInput>>>;
  checked?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  crossing_type?: InputMaybe<ModelSubscriptionStringInput>;
  crossing_url?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCrossingFilterInput>>>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionFixAnalyzedEntityTaskFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionFixAnalyzedEntityTaskFilterInput>>>;
  app_name?: InputMaybe<ModelSubscriptionStringInput>;
  app_version?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  new_location?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionFixAnalyzedEntityTaskFilterInput>>>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  user?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionFloatInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  ge?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  le?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type ModelSubscriptionIdInput = {
  beginsWith?: InputMaybe<Scalars['ID']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  ge?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  le?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ModelSubscriptionIntInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ModelSubscriptionInterpolationFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionInterpolationFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  interpolation_type?: InputMaybe<ModelSubscriptionStringInput>;
  interpolation_url?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionInterpolationFilterInput>>>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionScanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanFilterInput>>>;
  country?: InputMaybe<ModelSubscriptionStringInput>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  is_source_of_unified?: InputMaybe<ModelSubscriptionBooleanInput>;
  is_unified?: InputMaybe<ModelSubscriptionBooleanInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanFilterInput>>>;
  orthophoto_layer_url?: InputMaybe<ModelSubscriptionStringInput>;
  platform_type?: InputMaybe<ModelSubscriptionStringInput>;
  scanAnalyzed_entitiesId?: InputMaybe<ModelSubscriptionIdInput>;
  scanInterpolationsId?: InputMaybe<ModelSubscriptionIdInput>;
  scanScan_frequenciesId?: InputMaybe<ModelSubscriptionIdInput>;
  scanUnified_wrapperId?: InputMaybe<ModelSubscriptionIdInput>;
  scan_date?: InputMaybe<ModelSubscriptionStringInput>;
  scan_name?: InputMaybe<ModelSubscriptionStringInput>;
  scan_path?: InputMaybe<ModelSubscriptionStringInput>;
  scan_path_fix_mode_view?: InputMaybe<ModelSubscriptionStringInput>;
  scan_path_url?: InputMaybe<ModelSubscriptionStringInput>;
  sensor?: InputMaybe<ModelSubscriptionStringInput>;
  site_name?: InputMaybe<ModelSubscriptionStringInput>;
  timestamp_utc?: InputMaybe<ModelSubscriptionStringInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionScanFrequencyFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanFrequencyFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  frequency?: InputMaybe<ModelSubscriptionFloatInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanFrequencyFilterInput>>>;
  scanFrequencyAnalyzed_entitiesId?: InputMaybe<ModelSubscriptionIdInput>;
  scanFrequencyInterpolationsId?: InputMaybe<ModelSubscriptionIdInput>;
  scanFrequencyTransmittersId?: InputMaybe<ModelSubscriptionIdInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
  y_peaks_view?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionSiteFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionSiteFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionSiteFilterInput>>>;
  siteAnalyzed_entitiesId?: InputMaybe<ModelSubscriptionIdInput>;
  siteScanId?: InputMaybe<ModelSubscriptionIdInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionStringInput = {
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ge?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  le?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ModelSubscriptionTransmitterFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionTransmitterFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  frequency?: InputMaybe<ModelSubscriptionFloatInput>;
  geometry?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  method?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionTransmitterFilterInput>>>;
  snr?: InputMaybe<ModelSubscriptionFloatInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionUnifiedScanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionUnifiedScanFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionUnifiedScanFilterInput>>>;
  unifiedScanBase_scanId?: InputMaybe<ModelSubscriptionIdInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionUnifiedScansConnectionFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionUnifiedScansConnectionFilterInput>>>;
  createdAt?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionUnifiedScansConnectionFilterInput>>>;
  scanId?: InputMaybe<ModelSubscriptionIdInput>;
  unifiedScanId?: InputMaybe<ModelSubscriptionIdInput>;
  updatedAt?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelTransmitterConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTransmitterConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  geometry?: InputMaybe<ModelStringInput>;
  method?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelTransmitterConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTransmitterConditionInput>>>;
  scanFrequencyTransmittersId?: InputMaybe<ModelIdInput>;
  snr?: InputMaybe<ModelFloatInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelTransmitterConnection = {
  __typename?: 'ModelTransmitterConnection';
  items: Array<Maybe<Transmitter>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelTransmitterFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelTransmitterFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  geometry?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  method?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelTransmitterFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelTransmitterFilterInput>>>;
  scanFrequencyTransmittersId?: InputMaybe<ModelIdInput>;
  snr?: InputMaybe<ModelFloatInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelUnifiedScanConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUnifiedScanConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelUnifiedScanConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUnifiedScanConditionInput>>>;
  unifiedScanBase_scanId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelUnifiedScanConnection = {
  __typename?: 'ModelUnifiedScanConnection';
  items: Array<Maybe<UnifiedScan>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelUnifiedScanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUnifiedScanFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelUnifiedScanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUnifiedScanFilterInput>>>;
  unifiedScanBase_scanId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelUnifiedScansConnectionConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUnifiedScansConnectionConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelUnifiedScansConnectionConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUnifiedScansConnectionConditionInput>>>;
  scanId?: InputMaybe<ModelIdInput>;
  unifiedScanId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type ModelUnifiedScansConnectionConnection = {
  __typename?: 'ModelUnifiedScansConnectionConnection';
  items: Array<Maybe<UnifiedScansConnection>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelUnifiedScansConnectionFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUnifiedScansConnectionFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelUnifiedScansConnectionFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUnifiedScansConnectionFilterInput>>>;
  scanId?: InputMaybe<ModelIdInput>;
  unifiedScanId?: InputMaybe<ModelIdInput>;
  updatedAt?: InputMaybe<ModelStringInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAnalyzedEntity?: Maybe<AnalyzedEntity>;
  createChange?: Maybe<Change>;
  createComment?: Maybe<Comment>;
  createCrossing?: Maybe<Crossing>;
  createFixAnalyzedEntityTask?: Maybe<FixAnalyzedEntityTask>;
  createInterpolation?: Maybe<Interpolation>;
  createScan?: Maybe<Scan>;
  createScanFrequency?: Maybe<ScanFrequency>;
  createSite?: Maybe<Site>;
  createTransmitter?: Maybe<Transmitter>;
  createUnifiedScan?: Maybe<UnifiedScan>;
  createUnifiedScansConnection?: Maybe<UnifiedScansConnection>;
  deleteAnalyzedEntity?: Maybe<AnalyzedEntity>;
  deleteChange?: Maybe<Change>;
  deleteComment?: Maybe<Comment>;
  deleteCrossing?: Maybe<Crossing>;
  deleteFixAnalyzedEntityTask?: Maybe<FixAnalyzedEntityTask>;
  deleteInterpolation?: Maybe<Interpolation>;
  deleteScan?: Maybe<Scan>;
  deleteScanFrequency?: Maybe<ScanFrequency>;
  deleteSite?: Maybe<Site>;
  deleteTransmitter?: Maybe<Transmitter>;
  deleteUnifiedScan?: Maybe<UnifiedScan>;
  deleteUnifiedScansConnection?: Maybe<UnifiedScansConnection>;
  updateAnalyzedEntity?: Maybe<AnalyzedEntity>;
  updateChange?: Maybe<Change>;
  updateComment?: Maybe<Comment>;
  updateCrossing?: Maybe<Crossing>;
  updateFixAnalyzedEntityTask?: Maybe<FixAnalyzedEntityTask>;
  updateInterpolation?: Maybe<Interpolation>;
  updateScan?: Maybe<Scan>;
  updateScanFrequency?: Maybe<ScanFrequency>;
  updateSite?: Maybe<Site>;
  updateTransmitter?: Maybe<Transmitter>;
  updateUnifiedScan?: Maybe<UnifiedScan>;
  updateUnifiedScansConnection?: Maybe<UnifiedScansConnection>;
};


export type MutationCreateAnalyzedEntityArgs = {
  condition?: InputMaybe<ModelAnalyzedEntityConditionInput>;
  input: CreateAnalyzedEntityInput;
};


export type MutationCreateChangeArgs = {
  condition?: InputMaybe<ModelChangeConditionInput>;
  input: CreateChangeInput;
};


export type MutationCreateCommentArgs = {
  condition?: InputMaybe<ModelCommentConditionInput>;
  input: CreateCommentInput;
};


export type MutationCreateCrossingArgs = {
  condition?: InputMaybe<ModelCrossingConditionInput>;
  input: CreateCrossingInput;
};


export type MutationCreateFixAnalyzedEntityTaskArgs = {
  condition?: InputMaybe<ModelFixAnalyzedEntityTaskConditionInput>;
  input: CreateFixAnalyzedEntityTaskInput;
};


export type MutationCreateInterpolationArgs = {
  condition?: InputMaybe<ModelInterpolationConditionInput>;
  input: CreateInterpolationInput;
};


export type MutationCreateScanArgs = {
  condition?: InputMaybe<ModelScanConditionInput>;
  input: CreateScanInput;
};


export type MutationCreateScanFrequencyArgs = {
  condition?: InputMaybe<ModelScanFrequencyConditionInput>;
  input: CreateScanFrequencyInput;
};


export type MutationCreateSiteArgs = {
  condition?: InputMaybe<ModelSiteConditionInput>;
  input: CreateSiteInput;
};


export type MutationCreateTransmitterArgs = {
  condition?: InputMaybe<ModelTransmitterConditionInput>;
  input: CreateTransmitterInput;
};


export type MutationCreateUnifiedScanArgs = {
  condition?: InputMaybe<ModelUnifiedScanConditionInput>;
  input: CreateUnifiedScanInput;
};


export type MutationCreateUnifiedScansConnectionArgs = {
  condition?: InputMaybe<ModelUnifiedScansConnectionConditionInput>;
  input: CreateUnifiedScansConnectionInput;
};


export type MutationDeleteAnalyzedEntityArgs = {
  condition?: InputMaybe<ModelAnalyzedEntityConditionInput>;
  input: DeleteAnalyzedEntityInput;
};


export type MutationDeleteChangeArgs = {
  condition?: InputMaybe<ModelChangeConditionInput>;
  input: DeleteChangeInput;
};


export type MutationDeleteCommentArgs = {
  condition?: InputMaybe<ModelCommentConditionInput>;
  input: DeleteCommentInput;
};


export type MutationDeleteCrossingArgs = {
  condition?: InputMaybe<ModelCrossingConditionInput>;
  input: DeleteCrossingInput;
};


export type MutationDeleteFixAnalyzedEntityTaskArgs = {
  condition?: InputMaybe<ModelFixAnalyzedEntityTaskConditionInput>;
  input: DeleteFixAnalyzedEntityTaskInput;
};


export type MutationDeleteInterpolationArgs = {
  condition?: InputMaybe<ModelInterpolationConditionInput>;
  input: DeleteInterpolationInput;
};


export type MutationDeleteScanArgs = {
  condition?: InputMaybe<ModelScanConditionInput>;
  input: DeleteScanInput;
};


export type MutationDeleteScanFrequencyArgs = {
  condition?: InputMaybe<ModelScanFrequencyConditionInput>;
  input: DeleteScanFrequencyInput;
};


export type MutationDeleteSiteArgs = {
  condition?: InputMaybe<ModelSiteConditionInput>;
  input: DeleteSiteInput;
};


export type MutationDeleteTransmitterArgs = {
  condition?: InputMaybe<ModelTransmitterConditionInput>;
  input: DeleteTransmitterInput;
};


export type MutationDeleteUnifiedScanArgs = {
  condition?: InputMaybe<ModelUnifiedScanConditionInput>;
  input: DeleteUnifiedScanInput;
};


export type MutationDeleteUnifiedScansConnectionArgs = {
  condition?: InputMaybe<ModelUnifiedScansConnectionConditionInput>;
  input: DeleteUnifiedScansConnectionInput;
};


export type MutationUpdateAnalyzedEntityArgs = {
  condition?: InputMaybe<ModelAnalyzedEntityConditionInput>;
  input: UpdateAnalyzedEntityInput;
};


export type MutationUpdateChangeArgs = {
  condition?: InputMaybe<ModelChangeConditionInput>;
  input: UpdateChangeInput;
};


export type MutationUpdateCommentArgs = {
  condition?: InputMaybe<ModelCommentConditionInput>;
  input: UpdateCommentInput;
};


export type MutationUpdateCrossingArgs = {
  condition?: InputMaybe<ModelCrossingConditionInput>;
  input: UpdateCrossingInput;
};


export type MutationUpdateFixAnalyzedEntityTaskArgs = {
  condition?: InputMaybe<ModelFixAnalyzedEntityTaskConditionInput>;
  input: UpdateFixAnalyzedEntityTaskInput;
};


export type MutationUpdateInterpolationArgs = {
  condition?: InputMaybe<ModelInterpolationConditionInput>;
  input: UpdateInterpolationInput;
};


export type MutationUpdateScanArgs = {
  condition?: InputMaybe<ModelScanConditionInput>;
  input: UpdateScanInput;
};


export type MutationUpdateScanFrequencyArgs = {
  condition?: InputMaybe<ModelScanFrequencyConditionInput>;
  input: UpdateScanFrequencyInput;
};


export type MutationUpdateSiteArgs = {
  condition?: InputMaybe<ModelSiteConditionInput>;
  input: UpdateSiteInput;
};


export type MutationUpdateTransmitterArgs = {
  condition?: InputMaybe<ModelTransmitterConditionInput>;
  input: UpdateTransmitterInput;
};


export type MutationUpdateUnifiedScanArgs = {
  condition?: InputMaybe<ModelUnifiedScanConditionInput>;
  input: UpdateUnifiedScanInput;
};


export type MutationUpdateUnifiedScansConnectionArgs = {
  condition?: InputMaybe<ModelUnifiedScansConnectionConditionInput>;
  input: UpdateUnifiedScansConnectionInput;
};

export type Query = {
  __typename?: 'Query';
  getAnalyzedEntity?: Maybe<AnalyzedEntity>;
  getChange?: Maybe<Change>;
  getComment?: Maybe<Comment>;
  getCrossing?: Maybe<Crossing>;
  getFixAnalyzedEntityTask?: Maybe<FixAnalyzedEntityTask>;
  getInterpolation?: Maybe<Interpolation>;
  getScan?: Maybe<Scan>;
  getScanFrequency?: Maybe<ScanFrequency>;
  getSite?: Maybe<Site>;
  getTransmitter?: Maybe<Transmitter>;
  getUnifiedScan?: Maybe<UnifiedScan>;
  getUnifiedScansConnection?: Maybe<UnifiedScansConnection>;
  listAnalyzedEntities?: Maybe<ModelAnalyzedEntityConnection>;
  listChanges?: Maybe<ModelChangeConnection>;
  listComments?: Maybe<ModelCommentConnection>;
  listCrossings?: Maybe<ModelCrossingConnection>;
  listFixAnalyzedEntityTasks?: Maybe<ModelFixAnalyzedEntityTaskConnection>;
  listInterpolations?: Maybe<ModelInterpolationConnection>;
  listScanFrequencies?: Maybe<ModelScanFrequencyConnection>;
  listScans?: Maybe<ModelScanConnection>;
  listSites?: Maybe<ModelSiteConnection>;
  listTransmitters?: Maybe<ModelTransmitterConnection>;
  listUnifiedScans?: Maybe<ModelUnifiedScanConnection>;
  listUnifiedScansConnections?: Maybe<ModelUnifiedScansConnectionConnection>;
  searchAnalyzedEntities?: Maybe<SearchableAnalyzedEntityConnection>;
  searchChanges?: Maybe<SearchableChangeConnection>;
  searchComments?: Maybe<SearchableCommentConnection>;
  searchCrossings?: Maybe<SearchableCrossingConnection>;
  searchFixAnalyzedEntityTasks?: Maybe<SearchableFixAnalyzedEntityTaskConnection>;
  searchInterpolations?: Maybe<SearchableInterpolationConnection>;
  searchScanFrequencies?: Maybe<SearchableScanFrequencyConnection>;
  searchScans?: Maybe<SearchableScanConnection>;
  searchSites?: Maybe<SearchableSiteConnection>;
  searchTransmitters?: Maybe<SearchableTransmitterConnection>;
  searchUnifiedScans?: Maybe<SearchableUnifiedScanConnection>;
  unifiedScansConnectionsByScanId?: Maybe<ModelUnifiedScansConnectionConnection>;
  unifiedScansConnectionsByUnifiedScanId?: Maybe<ModelUnifiedScansConnectionConnection>;
};


export type QueryGetAnalyzedEntityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetChangeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCommentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCrossingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetFixAnalyzedEntityTaskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetInterpolationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetScanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetScanFrequencyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSiteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetTransmitterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetUnifiedScanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetUnifiedScansConnectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryListAnalyzedEntitiesArgs = {
  filter?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListChangesArgs = {
  filter?: InputMaybe<ModelChangeFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListCommentsArgs = {
  filter?: InputMaybe<ModelCommentFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListCrossingsArgs = {
  filter?: InputMaybe<ModelCrossingFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListFixAnalyzedEntityTasksArgs = {
  filter?: InputMaybe<ModelFixAnalyzedEntityTaskFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListInterpolationsArgs = {
  filter?: InputMaybe<ModelInterpolationFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListScanFrequenciesArgs = {
  filter?: InputMaybe<ModelScanFrequencyFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListScansArgs = {
  filter?: InputMaybe<ModelScanFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListSitesArgs = {
  filter?: InputMaybe<ModelSiteFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListTransmittersArgs = {
  filter?: InputMaybe<ModelTransmitterFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListUnifiedScansArgs = {
  filter?: InputMaybe<ModelUnifiedScanFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListUnifiedScansConnectionsArgs = {
  filter?: InputMaybe<ModelUnifiedScansConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchAnalyzedEntitiesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableAnalyzedEntityAggregationInput>>>;
  filter?: InputMaybe<SearchableAnalyzedEntityFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableAnalyzedEntitySortInput>>>;
};


export type QuerySearchChangesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableChangeAggregationInput>>>;
  filter?: InputMaybe<SearchableChangeFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableChangeSortInput>>>;
};


export type QuerySearchCommentsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableCommentAggregationInput>>>;
  filter?: InputMaybe<SearchableCommentFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableCommentSortInput>>>;
};


export type QuerySearchCrossingsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableCrossingAggregationInput>>>;
  filter?: InputMaybe<SearchableCrossingFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableCrossingSortInput>>>;
};


export type QuerySearchFixAnalyzedEntityTasksArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableFixAnalyzedEntityTaskAggregationInput>>>;
  filter?: InputMaybe<SearchableFixAnalyzedEntityTaskFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableFixAnalyzedEntityTaskSortInput>>>;
};


export type QuerySearchInterpolationsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableInterpolationAggregationInput>>>;
  filter?: InputMaybe<SearchableInterpolationFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableInterpolationSortInput>>>;
};


export type QuerySearchScanFrequenciesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableScanFrequencyAggregationInput>>>;
  filter?: InputMaybe<SearchableScanFrequencyFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableScanFrequencySortInput>>>;
};


export type QuerySearchScansArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableScanAggregationInput>>>;
  filter?: InputMaybe<SearchableScanFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableScanSortInput>>>;
};


export type QuerySearchSitesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableSiteAggregationInput>>>;
  filter?: InputMaybe<SearchableSiteFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableSiteSortInput>>>;
};


export type QuerySearchTransmittersArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableTransmitterAggregationInput>>>;
  filter?: InputMaybe<SearchableTransmitterFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableTransmitterSortInput>>>;
};


export type QuerySearchUnifiedScansArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableUnifiedScanAggregationInput>>>;
  filter?: InputMaybe<SearchableUnifiedScanFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableUnifiedScanSortInput>>>;
};


export type QueryUnifiedScansConnectionsByScanIdArgs = {
  filter?: InputMaybe<ModelUnifiedScansConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  scanId: Scalars['ID']['input'];
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryUnifiedScansConnectionsByUnifiedScanIdArgs = {
  filter?: InputMaybe<ModelUnifiedScansConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  unifiedScanId: Scalars['ID']['input'];
};

export type Scan = {
  __typename?: 'Scan';
  analyzed_entities?: Maybe<ModelAnalyzedEntityConnection>;
  country: Scalars['String']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  interpolations?: Maybe<ModelInterpolationConnection>;
  is_source_of_unified?: Maybe<Scalars['Boolean']['output']>;
  is_unified?: Maybe<Scalars['Boolean']['output']>;
  orthophoto_layer_url?: Maybe<Scalars['String']['output']>;
  platform_type?: Maybe<Scalars['String']['output']>;
  scanUnified_wrapperId?: Maybe<Scalars['ID']['output']>;
  scan_date: Scalars['AWSDate']['output'];
  scan_frequencies?: Maybe<ModelScanFrequencyConnection>;
  scan_name: Scalars['String']['output'];
  scan_path: Scalars['String']['output'];
  scan_path_fix_mode_view?: Maybe<Scalars['String']['output']>;
  scan_path_url?: Maybe<Scalars['String']['output']>;
  sensor?: Maybe<Scalars['String']['output']>;
  site?: Maybe<Site>;
  siteScanId?: Maybe<Scalars['ID']['output']>;
  site_name: Scalars['String']['output'];
  timestamp_utc?: Maybe<Scalars['String']['output']>;
  unified_scans?: Maybe<ModelUnifiedScansConnectionConnection>;
  unified_wrapper?: Maybe<UnifiedScan>;
  updatedAt: Scalars['AWSDateTime']['output'];
};


export type ScanAnalyzed_EntitiesArgs = {
  filter?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanInterpolationsArgs = {
  filter?: InputMaybe<ModelInterpolationFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanScan_FrequenciesArgs = {
  filter?: InputMaybe<ModelScanFrequencyFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanUnified_ScansArgs = {
  filter?: InputMaybe<ModelUnifiedScansConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type ScanFrequency = {
  __typename?: 'ScanFrequency';
  analyzed_entities?: Maybe<ModelAnalyzedEntityConnection>;
  createdAt: Scalars['AWSDateTime']['output'];
  frequency: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  interpolations?: Maybe<ModelInterpolationConnection>;
  scan?: Maybe<Scan>;
  scanScan_frequenciesId?: Maybe<Scalars['ID']['output']>;
  transmitters?: Maybe<ModelTransmitterConnection>;
  updatedAt: Scalars['AWSDateTime']['output'];
  y_peaks_view?: Maybe<Scalars['String']['output']>;
};


export type ScanFrequencyAnalyzed_EntitiesArgs = {
  filter?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanFrequencyInterpolationsArgs = {
  filter?: InputMaybe<ModelInterpolationFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanFrequencyTransmittersArgs = {
  filter?: InputMaybe<ModelTransmitterFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type SearchableAggregateBucketResult = {
  __typename?: 'SearchableAggregateBucketResult';
  buckets?: Maybe<Array<Maybe<SearchableAggregateBucketResultItem>>>;
};

export type SearchableAggregateBucketResultItem = {
  __typename?: 'SearchableAggregateBucketResultItem';
  doc_count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
};

export type SearchableAggregateGenericResult = SearchableAggregateBucketResult | SearchableAggregateScalarResult;

export type SearchableAggregateResult = {
  __typename?: 'SearchableAggregateResult';
  name: Scalars['String']['output'];
  result?: Maybe<SearchableAggregateGenericResult>;
};

export type SearchableAggregateScalarResult = {
  __typename?: 'SearchableAggregateScalarResult';
  value: Scalars['Float']['output'];
};

export enum SearchableAggregateType {
  Avg = 'avg',
  Cardinality = 'cardinality',
  Max = 'max',
  Min = 'min',
  Sum = 'sum',
  Terms = 'terms'
}

export enum SearchableAnalyzedEntityAggregateField {
  Algorithm = 'algorithm',
  AnalysisConfidence = 'analysis_confidence',
  AnalysisEdgeOfScan = 'analysis_edge_of_scan',
  AnalyzedEntityFixedPipesId = 'analyzedEntityFixed_pipesId',
  AutoEdgeOfScan = 'auto_edge_of_scan',
  ChangedBy = 'changed_by',
  Confidence = 'confidence',
  CreatedAt = 'createdAt',
  DepthResultPerAlgorithm = 'depth_result_per_algorithm',
  ExoanalyzerFlagged = 'exoanalyzer_flagged',
  Flagged = 'flagged',
  Frequency = 'frequency',
  Geometry = 'geometry',
  Id = 'id',
  MeanAngleCrossings = 'mean_angle_crossings',
  NCrossings = 'n_crossings',
  PipeCrossings = 'pipe_crossings',
  PipeDepths = 'pipe_depths',
  PipesqaEditIsFixed = 'pipesqa_edit_is_fixed',
  PipesqaEditIsOriginal = 'pipesqa_edit_is_original',
  Prefix = 'prefix',
  Probability = 'probability',
  ScanAnalyzedEntitiesId = 'scanAnalyzed_entitiesId',
  ScanFrequencyAnalyzedEntitiesId = 'scanFrequencyAnalyzed_entitiesId',
  SiteAnalyzedEntitiesId = 'siteAnalyzed_entitiesId',
  Snr = 'snr',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  ZPeaksDepthAlgoView = 'z_peaks_depth_algo_view'
}

export type SearchableAnalyzedEntityAggregationInput = {
  field: SearchableAnalyzedEntityAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableAnalyzedEntityConnection = {
  __typename?: 'SearchableAnalyzedEntityConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<AnalyzedEntity>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableAnalyzedEntityFilterInput = {
  algorithm?: InputMaybe<SearchableStringFilterInput>;
  analysis_confidence?: InputMaybe<SearchableStringFilterInput>;
  analysis_edge_of_scan?: InputMaybe<SearchableBooleanFilterInput>;
  analyzedEntityFixed_pipesId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableAnalyzedEntityFilterInput>>>;
  auto_edge_of_scan?: InputMaybe<SearchableBooleanFilterInput>;
  changed_by?: InputMaybe<SearchableStringFilterInput>;
  confidence?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  depth_result_per_algorithm?: InputMaybe<SearchableStringFilterInput>;
  exoanalyzer_flagged?: InputMaybe<SearchableBooleanFilterInput>;
  flagged?: InputMaybe<SearchableBooleanFilterInput>;
  frequency?: InputMaybe<SearchableFloatFilterInput>;
  geometry?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  mean_angle_crossings?: InputMaybe<SearchableFloatFilterInput>;
  n_crossings?: InputMaybe<SearchableIntFilterInput>;
  not?: InputMaybe<SearchableAnalyzedEntityFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableAnalyzedEntityFilterInput>>>;
  pipe_crossings?: InputMaybe<SearchableStringFilterInput>;
  pipe_depths?: InputMaybe<SearchableStringFilterInput>;
  pipesqa_edit_is_fixed?: InputMaybe<SearchableBooleanFilterInput>;
  pipesqa_edit_is_original?: InputMaybe<SearchableBooleanFilterInput>;
  prefix?: InputMaybe<SearchableStringFilterInput>;
  probability?: InputMaybe<SearchableFloatFilterInput>;
  scanAnalyzed_entitiesId?: InputMaybe<SearchableIdFilterInput>;
  scanFrequencyAnalyzed_entitiesId?: InputMaybe<SearchableIdFilterInput>;
  siteAnalyzed_entitiesId?: InputMaybe<SearchableIdFilterInput>;
  snr?: InputMaybe<SearchableFloatFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  z_peaks_depth_algo_view?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableAnalyzedEntitySortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableAnalyzedEntitySortableFields>;
};

export enum SearchableAnalyzedEntitySortableFields {
  AnalysisEdgeOfScan = 'analysis_edge_of_scan',
  AnalyzedEntityFixedPipesId = 'analyzedEntityFixed_pipesId',
  AutoEdgeOfScan = 'auto_edge_of_scan',
  ChangedBy = 'changed_by',
  CreatedAt = 'createdAt',
  DepthResultPerAlgorithm = 'depth_result_per_algorithm',
  ExoanalyzerFlagged = 'exoanalyzer_flagged',
  Flagged = 'flagged',
  Frequency = 'frequency',
  Geometry = 'geometry',
  Id = 'id',
  MeanAngleCrossings = 'mean_angle_crossings',
  NCrossings = 'n_crossings',
  PipeCrossings = 'pipe_crossings',
  PipeDepths = 'pipe_depths',
  PipesqaEditIsFixed = 'pipesqa_edit_is_fixed',
  PipesqaEditIsOriginal = 'pipesqa_edit_is_original',
  Prefix = 'prefix',
  Probability = 'probability',
  ScanAnalyzedEntitiesId = 'scanAnalyzed_entitiesId',
  ScanFrequencyAnalyzedEntitiesId = 'scanFrequencyAnalyzed_entitiesId',
  SiteAnalyzedEntitiesId = 'siteAnalyzed_entitiesId',
  Snr = 'snr',
  UpdatedAt = 'updatedAt',
  ZPeaksDepthAlgoView = 'z_peaks_depth_algo_view'
}

export type SearchableBooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SearchableChangeAggregateField {
  AnalyzedEntityChangesId = 'analyzedEntityChangesId',
  Change = 'change',
  ChangedBy = 'changed_by',
  CreatedAt = 'createdAt',
  Id = 'id',
  Timestamp = 'timestamp',
  UpdatedAt = 'updatedAt'
}

export type SearchableChangeAggregationInput = {
  field: SearchableChangeAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableChangeConnection = {
  __typename?: 'SearchableChangeConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Change>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableChangeFilterInput = {
  analyzedEntityChangesId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableChangeFilterInput>>>;
  change?: InputMaybe<SearchableStringFilterInput>;
  changed_by?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableChangeFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableChangeFilterInput>>>;
  timestamp?: InputMaybe<SearchableIntFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableChangeSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableChangeSortableFields>;
};

export enum SearchableChangeSortableFields {
  AnalyzedEntityChangesId = 'analyzedEntityChangesId',
  Change = 'change',
  ChangedBy = 'changed_by',
  CreatedAt = 'createdAt',
  Id = 'id',
  Timestamp = 'timestamp',
  UpdatedAt = 'updatedAt'
}

export enum SearchableCommentAggregateField {
  AnalyzedEntityCommentsId = 'analyzedEntityCommentsId',
  ChangedBy = 'changed_by',
  CreatedAt = 'createdAt',
  Id = 'id',
  Text = 'text',
  Timestamp = 'timestamp',
  UpdatedAt = 'updatedAt'
}

export type SearchableCommentAggregationInput = {
  field: SearchableCommentAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableCommentConnection = {
  __typename?: 'SearchableCommentConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Comment>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableCommentFilterInput = {
  analyzedEntityCommentsId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableCommentFilterInput>>>;
  changed_by?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableCommentFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableCommentFilterInput>>>;
  text?: InputMaybe<SearchableStringFilterInput>;
  timestamp?: InputMaybe<SearchableIntFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableCommentSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableCommentSortableFields>;
};

export enum SearchableCommentSortableFields {
  AnalyzedEntityCommentsId = 'analyzedEntityCommentsId',
  ChangedBy = 'changed_by',
  CreatedAt = 'createdAt',
  Id = 'id',
  Text = 'text',
  Timestamp = 'timestamp',
  UpdatedAt = 'updatedAt'
}

export enum SearchableCrossingAggregateField {
  AnalyzedEntityCrossingsId = 'analyzedEntityCrossingsId',
  Checked = 'checked',
  CreatedAt = 'createdAt',
  CrossingType = 'crossing_type',
  CrossingUrl = 'crossing_url',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type SearchableCrossingAggregationInput = {
  field: SearchableCrossingAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableCrossingConnection = {
  __typename?: 'SearchableCrossingConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Crossing>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableCrossingFilterInput = {
  analyzedEntityCrossingsId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableCrossingFilterInput>>>;
  checked?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  crossing_type?: InputMaybe<SearchableStringFilterInput>;
  crossing_url?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableCrossingFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableCrossingFilterInput>>>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableCrossingSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableCrossingSortableFields>;
};

export enum SearchableCrossingSortableFields {
  AnalyzedEntityCrossingsId = 'analyzedEntityCrossingsId',
  CreatedAt = 'createdAt',
  CrossingType = 'crossing_type',
  CrossingUrl = 'crossing_url',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export enum SearchableFixAnalyzedEntityTaskAggregateField {
  AnalyzedEntityFixPipesTasksId = 'analyzedEntityFix_pipes_tasksId',
  AppName = 'app_name',
  AppVersion = 'app_version',
  CreatedAt = 'createdAt',
  Id = 'id',
  NewLocation = 'new_location',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  User = 'user'
}

export type SearchableFixAnalyzedEntityTaskAggregationInput = {
  field: SearchableFixAnalyzedEntityTaskAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableFixAnalyzedEntityTaskConnection = {
  __typename?: 'SearchableFixAnalyzedEntityTaskConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<FixAnalyzedEntityTask>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableFixAnalyzedEntityTaskFilterInput = {
  analyzedEntityFix_pipes_tasksId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableFixAnalyzedEntityTaskFilterInput>>>;
  app_name?: InputMaybe<SearchableStringFilterInput>;
  app_version?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  new_location?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableFixAnalyzedEntityTaskFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableFixAnalyzedEntityTaskFilterInput>>>;
  status?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  user?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableFixAnalyzedEntityTaskSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableFixAnalyzedEntityTaskSortableFields>;
};

export enum SearchableFixAnalyzedEntityTaskSortableFields {
  AnalyzedEntityFixPipesTasksId = 'analyzedEntityFix_pipes_tasksId',
  AppName = 'app_name',
  AppVersion = 'app_version',
  CreatedAt = 'createdAt',
  Id = 'id',
  NewLocation = 'new_location',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  User = 'user'
}

export type SearchableFloatFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type SearchableIdFilterInput = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  match?: InputMaybe<Scalars['ID']['input']>;
  matchPhrase?: InputMaybe<Scalars['ID']['input']>;
  matchPhrasePrefix?: InputMaybe<Scalars['ID']['input']>;
  multiMatch?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  regexp?: InputMaybe<Scalars['ID']['input']>;
  wildcard?: InputMaybe<Scalars['ID']['input']>;
};

export type SearchableIntFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum SearchableInterpolationAggregateField {
  CreatedAt = 'createdAt',
  Id = 'id',
  InterpolationType = 'interpolation_type',
  InterpolationUrl = 'interpolation_url',
  ScanFrequencyInterpolationsId = 'scanFrequencyInterpolationsId',
  ScanInterpolationsId = 'scanInterpolationsId',
  UpdatedAt = 'updatedAt'
}

export type SearchableInterpolationAggregationInput = {
  field: SearchableInterpolationAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableInterpolationConnection = {
  __typename?: 'SearchableInterpolationConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Interpolation>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableInterpolationFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableInterpolationFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  interpolation_type?: InputMaybe<SearchableStringFilterInput>;
  interpolation_url?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableInterpolationFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableInterpolationFilterInput>>>;
  scanFrequencyInterpolationsId?: InputMaybe<SearchableIdFilterInput>;
  scanInterpolationsId?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableInterpolationSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableInterpolationSortableFields>;
};

export enum SearchableInterpolationSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  InterpolationType = 'interpolation_type',
  InterpolationUrl = 'interpolation_url',
  ScanFrequencyInterpolationsId = 'scanFrequencyInterpolationsId',
  ScanInterpolationsId = 'scanInterpolationsId',
  UpdatedAt = 'updatedAt'
}

export enum SearchableScanAggregateField {
  Country = 'country',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsSourceOfUnified = 'is_source_of_unified',
  IsUnified = 'is_unified',
  OrthophotoLayerUrl = 'orthophoto_layer_url',
  PlatformType = 'platform_type',
  ScanUnifiedWrapperId = 'scanUnified_wrapperId',
  ScanDate = 'scan_date',
  ScanName = 'scan_name',
  ScanPath = 'scan_path',
  ScanPathFixModeView = 'scan_path_fix_mode_view',
  ScanPathUrl = 'scan_path_url',
  Sensor = 'sensor',
  SiteScanId = 'siteScanId',
  SiteName = 'site_name',
  TimestampUtc = 'timestamp_utc',
  UpdatedAt = 'updatedAt'
}

export type SearchableScanAggregationInput = {
  field: SearchableScanAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableScanConnection = {
  __typename?: 'SearchableScanConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Scan>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableScanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableScanFilterInput>>>;
  country?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  is_source_of_unified?: InputMaybe<SearchableBooleanFilterInput>;
  is_unified?: InputMaybe<SearchableBooleanFilterInput>;
  not?: InputMaybe<SearchableScanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableScanFilterInput>>>;
  orthophoto_layer_url?: InputMaybe<SearchableStringFilterInput>;
  platform_type?: InputMaybe<SearchableStringFilterInput>;
  scanUnified_wrapperId?: InputMaybe<SearchableIdFilterInput>;
  scan_date?: InputMaybe<SearchableStringFilterInput>;
  scan_name?: InputMaybe<SearchableStringFilterInput>;
  scan_path?: InputMaybe<SearchableStringFilterInput>;
  scan_path_fix_mode_view?: InputMaybe<SearchableStringFilterInput>;
  scan_path_url?: InputMaybe<SearchableStringFilterInput>;
  sensor?: InputMaybe<SearchableStringFilterInput>;
  siteScanId?: InputMaybe<SearchableIdFilterInput>;
  site_name?: InputMaybe<SearchableStringFilterInput>;
  timestamp_utc?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export enum SearchableScanFrequencyAggregateField {
  CreatedAt = 'createdAt',
  Frequency = 'frequency',
  Id = 'id',
  ScanScanFrequenciesId = 'scanScan_frequenciesId',
  UpdatedAt = 'updatedAt',
  YPeaksView = 'y_peaks_view'
}

export type SearchableScanFrequencyAggregationInput = {
  field: SearchableScanFrequencyAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableScanFrequencyConnection = {
  __typename?: 'SearchableScanFrequencyConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<ScanFrequency>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableScanFrequencyFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableScanFrequencyFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  frequency?: InputMaybe<SearchableFloatFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableScanFrequencyFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableScanFrequencyFilterInput>>>;
  scanScan_frequenciesId?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
  y_peaks_view?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableScanFrequencySortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableScanFrequencySortableFields>;
};

export enum SearchableScanFrequencySortableFields {
  CreatedAt = 'createdAt',
  Frequency = 'frequency',
  Id = 'id',
  ScanScanFrequenciesId = 'scanScan_frequenciesId',
  UpdatedAt = 'updatedAt',
  YPeaksView = 'y_peaks_view'
}

export type SearchableScanSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableScanSortableFields>;
};

export enum SearchableScanSortableFields {
  Country = 'country',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsSourceOfUnified = 'is_source_of_unified',
  IsUnified = 'is_unified',
  OrthophotoLayerUrl = 'orthophoto_layer_url',
  PlatformType = 'platform_type',
  ScanUnifiedWrapperId = 'scanUnified_wrapperId',
  ScanDate = 'scan_date',
  ScanName = 'scan_name',
  ScanPath = 'scan_path',
  ScanPathFixModeView = 'scan_path_fix_mode_view',
  ScanPathUrl = 'scan_path_url',
  Sensor = 'sensor',
  SiteScanId = 'siteScanId',
  SiteName = 'site_name',
  TimestampUtc = 'timestamp_utc',
  UpdatedAt = 'updatedAt'
}

export enum SearchableSiteAggregateField {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type SearchableSiteAggregationInput = {
  field: SearchableSiteAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableSiteConnection = {
  __typename?: 'SearchableSiteConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Site>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableSiteFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableSiteFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  name?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableSiteFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableSiteFilterInput>>>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableSiteSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableSiteSortableFields>;
};

export enum SearchableSiteSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export enum SearchableSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type SearchableStringFilterInput = {
  eq?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  match?: InputMaybe<Scalars['String']['input']>;
  matchPhrase?: InputMaybe<Scalars['String']['input']>;
  matchPhrasePrefix?: InputMaybe<Scalars['String']['input']>;
  multiMatch?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regexp?: InputMaybe<Scalars['String']['input']>;
  wildcard?: InputMaybe<Scalars['String']['input']>;
};

export enum SearchableTransmitterAggregateField {
  CreatedAt = 'createdAt',
  Frequency = 'frequency',
  Geometry = 'geometry',
  Id = 'id',
  Method = 'method',
  ScanFrequencyTransmittersId = 'scanFrequencyTransmittersId',
  Snr = 'snr',
  UpdatedAt = 'updatedAt'
}

export type SearchableTransmitterAggregationInput = {
  field: SearchableTransmitterAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableTransmitterConnection = {
  __typename?: 'SearchableTransmitterConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Transmitter>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableTransmitterFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableTransmitterFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  frequency?: InputMaybe<SearchableFloatFilterInput>;
  geometry?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  method?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableTransmitterFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableTransmitterFilterInput>>>;
  scanFrequencyTransmittersId?: InputMaybe<SearchableIdFilterInput>;
  snr?: InputMaybe<SearchableFloatFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableTransmitterSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableTransmitterSortableFields>;
};

export enum SearchableTransmitterSortableFields {
  CreatedAt = 'createdAt',
  Frequency = 'frequency',
  Geometry = 'geometry',
  Id = 'id',
  Method = 'method',
  ScanFrequencyTransmittersId = 'scanFrequencyTransmittersId',
  Snr = 'snr',
  UpdatedAt = 'updatedAt'
}

export enum SearchableUnifiedScanAggregateField {
  CreatedAt = 'createdAt',
  Id = 'id',
  UnifiedScanBaseScanId = 'unifiedScanBase_scanId',
  UpdatedAt = 'updatedAt'
}

export type SearchableUnifiedScanAggregationInput = {
  field: SearchableUnifiedScanAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableUnifiedScanConnection = {
  __typename?: 'SearchableUnifiedScanConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<UnifiedScan>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableUnifiedScanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableUnifiedScanFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableUnifiedScanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableUnifiedScanFilterInput>>>;
  unifiedScanBase_scanId?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableUnifiedScanSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableUnifiedScanSortableFields>;
};

export enum SearchableUnifiedScanSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UnifiedScanBaseScanId = 'unifiedScanBase_scanId',
  UpdatedAt = 'updatedAt'
}

export type Site = {
  __typename?: 'Site';
  analyzed_entities?: Maybe<ModelAnalyzedEntityConnection>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  scan?: Maybe<ModelScanConnection>;
  updatedAt: Scalars['AWSDateTime']['output'];
};


export type SiteAnalyzed_EntitiesArgs = {
  filter?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type SiteScanArgs = {
  filter?: InputMaybe<ModelScanFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export enum Status {
  Approved = 'APPROVED',
  Edited = 'EDITED',
  InvalidatedByExofuser = 'INVALIDATED_BY_EXOFUSER',
  NoValidationRequired = 'NO_VALIDATION_REQUIRED',
  Pending = 'PENDING',
  PendingEditing = 'PENDING_EDITING',
  Rejected = 'REJECTED',
  Test = 'TEST'
}

export type Subscription = {
  __typename?: 'Subscription';
  onCreateAnalyzedEntity?: Maybe<AnalyzedEntity>;
  onCreateChange?: Maybe<Change>;
  onCreateComment?: Maybe<Comment>;
  onCreateCrossing?: Maybe<Crossing>;
  onCreateFixAnalyzedEntityTask?: Maybe<FixAnalyzedEntityTask>;
  onCreateInterpolation?: Maybe<Interpolation>;
  onCreateScan?: Maybe<Scan>;
  onCreateScanFrequency?: Maybe<ScanFrequency>;
  onCreateSite?: Maybe<Site>;
  onCreateTransmitter?: Maybe<Transmitter>;
  onCreateUnifiedScan?: Maybe<UnifiedScan>;
  onCreateUnifiedScansConnection?: Maybe<UnifiedScansConnection>;
  onDeleteAnalyzedEntity?: Maybe<AnalyzedEntity>;
  onDeleteChange?: Maybe<Change>;
  onDeleteComment?: Maybe<Comment>;
  onDeleteCrossing?: Maybe<Crossing>;
  onDeleteFixAnalyzedEntityTask?: Maybe<FixAnalyzedEntityTask>;
  onDeleteInterpolation?: Maybe<Interpolation>;
  onDeleteScan?: Maybe<Scan>;
  onDeleteScanFrequency?: Maybe<ScanFrequency>;
  onDeleteSite?: Maybe<Site>;
  onDeleteTransmitter?: Maybe<Transmitter>;
  onDeleteUnifiedScan?: Maybe<UnifiedScan>;
  onDeleteUnifiedScansConnection?: Maybe<UnifiedScansConnection>;
  onUpdateAnalyzedEntity?: Maybe<AnalyzedEntity>;
  onUpdateChange?: Maybe<Change>;
  onUpdateComment?: Maybe<Comment>;
  onUpdateCrossing?: Maybe<Crossing>;
  onUpdateFixAnalyzedEntityTask?: Maybe<FixAnalyzedEntityTask>;
  onUpdateInterpolation?: Maybe<Interpolation>;
  onUpdateScan?: Maybe<Scan>;
  onUpdateScanFrequency?: Maybe<ScanFrequency>;
  onUpdateSite?: Maybe<Site>;
  onUpdateTransmitter?: Maybe<Transmitter>;
  onUpdateUnifiedScan?: Maybe<UnifiedScan>;
  onUpdateUnifiedScansConnection?: Maybe<UnifiedScansConnection>;
};


export type SubscriptionOnCreateAnalyzedEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>;
};


export type SubscriptionOnCreateChangeArgs = {
  filter?: InputMaybe<ModelSubscriptionChangeFilterInput>;
};


export type SubscriptionOnCreateCommentArgs = {
  filter?: InputMaybe<ModelSubscriptionCommentFilterInput>;
};


export type SubscriptionOnCreateCrossingArgs = {
  filter?: InputMaybe<ModelSubscriptionCrossingFilterInput>;
};


export type SubscriptionOnCreateFixAnalyzedEntityTaskArgs = {
  filter?: InputMaybe<ModelSubscriptionFixAnalyzedEntityTaskFilterInput>;
};


export type SubscriptionOnCreateInterpolationArgs = {
  filter?: InputMaybe<ModelSubscriptionInterpolationFilterInput>;
};


export type SubscriptionOnCreateScanArgs = {
  filter?: InputMaybe<ModelSubscriptionScanFilterInput>;
};


export type SubscriptionOnCreateScanFrequencyArgs = {
  filter?: InputMaybe<ModelSubscriptionScanFrequencyFilterInput>;
};


export type SubscriptionOnCreateSiteArgs = {
  filter?: InputMaybe<ModelSubscriptionSiteFilterInput>;
};


export type SubscriptionOnCreateTransmitterArgs = {
  filter?: InputMaybe<ModelSubscriptionTransmitterFilterInput>;
};


export type SubscriptionOnCreateUnifiedScanArgs = {
  filter?: InputMaybe<ModelSubscriptionUnifiedScanFilterInput>;
};


export type SubscriptionOnCreateUnifiedScansConnectionArgs = {
  filter?: InputMaybe<ModelSubscriptionUnifiedScansConnectionFilterInput>;
};


export type SubscriptionOnDeleteAnalyzedEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>;
};


export type SubscriptionOnDeleteChangeArgs = {
  filter?: InputMaybe<ModelSubscriptionChangeFilterInput>;
};


export type SubscriptionOnDeleteCommentArgs = {
  filter?: InputMaybe<ModelSubscriptionCommentFilterInput>;
};


export type SubscriptionOnDeleteCrossingArgs = {
  filter?: InputMaybe<ModelSubscriptionCrossingFilterInput>;
};


export type SubscriptionOnDeleteFixAnalyzedEntityTaskArgs = {
  filter?: InputMaybe<ModelSubscriptionFixAnalyzedEntityTaskFilterInput>;
};


export type SubscriptionOnDeleteInterpolationArgs = {
  filter?: InputMaybe<ModelSubscriptionInterpolationFilterInput>;
};


export type SubscriptionOnDeleteScanArgs = {
  filter?: InputMaybe<ModelSubscriptionScanFilterInput>;
};


export type SubscriptionOnDeleteScanFrequencyArgs = {
  filter?: InputMaybe<ModelSubscriptionScanFrequencyFilterInput>;
};


export type SubscriptionOnDeleteSiteArgs = {
  filter?: InputMaybe<ModelSubscriptionSiteFilterInput>;
};


export type SubscriptionOnDeleteTransmitterArgs = {
  filter?: InputMaybe<ModelSubscriptionTransmitterFilterInput>;
};


export type SubscriptionOnDeleteUnifiedScanArgs = {
  filter?: InputMaybe<ModelSubscriptionUnifiedScanFilterInput>;
};


export type SubscriptionOnDeleteUnifiedScansConnectionArgs = {
  filter?: InputMaybe<ModelSubscriptionUnifiedScansConnectionFilterInput>;
};


export type SubscriptionOnUpdateAnalyzedEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>;
};


export type SubscriptionOnUpdateChangeArgs = {
  filter?: InputMaybe<ModelSubscriptionChangeFilterInput>;
};


export type SubscriptionOnUpdateCommentArgs = {
  filter?: InputMaybe<ModelSubscriptionCommentFilterInput>;
};


export type SubscriptionOnUpdateCrossingArgs = {
  filter?: InputMaybe<ModelSubscriptionCrossingFilterInput>;
};


export type SubscriptionOnUpdateFixAnalyzedEntityTaskArgs = {
  filter?: InputMaybe<ModelSubscriptionFixAnalyzedEntityTaskFilterInput>;
};


export type SubscriptionOnUpdateInterpolationArgs = {
  filter?: InputMaybe<ModelSubscriptionInterpolationFilterInput>;
};


export type SubscriptionOnUpdateScanArgs = {
  filter?: InputMaybe<ModelSubscriptionScanFilterInput>;
};


export type SubscriptionOnUpdateScanFrequencyArgs = {
  filter?: InputMaybe<ModelSubscriptionScanFrequencyFilterInput>;
};


export type SubscriptionOnUpdateSiteArgs = {
  filter?: InputMaybe<ModelSubscriptionSiteFilterInput>;
};


export type SubscriptionOnUpdateTransmitterArgs = {
  filter?: InputMaybe<ModelSubscriptionTransmitterFilterInput>;
};


export type SubscriptionOnUpdateUnifiedScanArgs = {
  filter?: InputMaybe<ModelSubscriptionUnifiedScanFilterInput>;
};


export type SubscriptionOnUpdateUnifiedScansConnectionArgs = {
  filter?: InputMaybe<ModelSubscriptionUnifiedScansConnectionFilterInput>;
};

export type Transmitter = {
  __typename?: 'Transmitter';
  createdAt: Scalars['AWSDateTime']['output'];
  frequency: Scalars['Float']['output'];
  geometry?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  method?: Maybe<Scalars['String']['output']>;
  scanFrequency?: Maybe<ScanFrequency>;
  scanFrequencyTransmittersId?: Maybe<Scalars['ID']['output']>;
  snr?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type UnifiedScan = {
  __typename?: 'UnifiedScan';
  base_scan?: Maybe<Scan>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  source_scans?: Maybe<ModelUnifiedScansConnectionConnection>;
  unifiedScanBase_scanId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
};


export type UnifiedScanSource_ScansArgs = {
  filter?: InputMaybe<ModelUnifiedScansConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type UnifiedScansConnection = {
  __typename?: 'UnifiedScansConnection';
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  scan: Scan;
  scanId: Scalars['ID']['output'];
  unifiedScan: UnifiedScan;
  unifiedScanId: Scalars['ID']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type UpdateAnalyzedEntityInput = {
  algorithm?: InputMaybe<Algorithm>;
  analysis_confidence?: InputMaybe<Confidence>;
  analysis_edge_of_scan?: InputMaybe<Scalars['Boolean']['input']>;
  analyzedEntityFixed_pipesId?: InputMaybe<Scalars['ID']['input']>;
  auto_edge_of_scan?: InputMaybe<Scalars['Boolean']['input']>;
  changed_by?: InputMaybe<Scalars['String']['input']>;
  confidence?: InputMaybe<Confidence>;
  depth_result_per_algorithm?: InputMaybe<Scalars['String']['input']>;
  exoanalyzer_flagged?: InputMaybe<Scalars['Boolean']['input']>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  frequency?: InputMaybe<Scalars['Float']['input']>;
  geometry?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  mean_angle_crossings?: InputMaybe<Scalars['Float']['input']>;
  n_crossings?: InputMaybe<Scalars['Int']['input']>;
  pipe_crossings?: InputMaybe<Scalars['String']['input']>;
  pipe_depths?: InputMaybe<Scalars['String']['input']>;
  pipesqa_edit_is_fixed?: InputMaybe<Scalars['Boolean']['input']>;
  pipesqa_edit_is_original?: InputMaybe<Scalars['Boolean']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  probability?: InputMaybe<Scalars['Float']['input']>;
  scanAnalyzed_entitiesId?: InputMaybe<Scalars['ID']['input']>;
  scanFrequencyAnalyzed_entitiesId?: InputMaybe<Scalars['ID']['input']>;
  siteAnalyzed_entitiesId?: InputMaybe<Scalars['ID']['input']>;
  snr?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Status>;
  z_peaks_depth_algo_view?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChangeInput = {
  analyzedEntityChangesId?: InputMaybe<Scalars['ID']['input']>;
  change?: InputMaybe<Scalars['String']['input']>;
  changed_by?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  timestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type UpdateCommentInput = {
  analyzedEntityCommentsId?: InputMaybe<Scalars['ID']['input']>;
  changed_by?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type UpdateCrossingInput = {
  analyzedEntityCrossingsId?: InputMaybe<Scalars['ID']['input']>;
  checked?: InputMaybe<Array<InputMaybe<Status>>>;
  crossing_type?: InputMaybe<Scalars['String']['input']>;
  crossing_url?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateFixAnalyzedEntityTaskInput = {
  analyzedEntityFix_pipes_tasksId?: InputMaybe<Scalars['ID']['input']>;
  app_name?: InputMaybe<Scalars['String']['input']>;
  app_version?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  new_location?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInterpolationInput = {
  id: Scalars['ID']['input'];
  interpolation_type?: InputMaybe<Scalars['String']['input']>;
  interpolation_url?: InputMaybe<Scalars['String']['input']>;
  scanFrequencyInterpolationsId?: InputMaybe<Scalars['ID']['input']>;
  scanInterpolationsId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateScanFrequencyInput = {
  frequency?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  scanScan_frequenciesId?: InputMaybe<Scalars['ID']['input']>;
  y_peaks_view?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateScanInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  is_source_of_unified?: InputMaybe<Scalars['Boolean']['input']>;
  is_unified?: InputMaybe<Scalars['Boolean']['input']>;
  orthophoto_layer_url?: InputMaybe<Scalars['String']['input']>;
  platform_type?: InputMaybe<Scalars['String']['input']>;
  scanUnified_wrapperId?: InputMaybe<Scalars['ID']['input']>;
  scan_date?: InputMaybe<Scalars['AWSDate']['input']>;
  scan_name?: InputMaybe<Scalars['String']['input']>;
  scan_path?: InputMaybe<Scalars['String']['input']>;
  scan_path_fix_mode_view?: InputMaybe<Scalars['String']['input']>;
  scan_path_url?: InputMaybe<Scalars['String']['input']>;
  sensor?: InputMaybe<Scalars['String']['input']>;
  siteScanId?: InputMaybe<Scalars['ID']['input']>;
  site_name?: InputMaybe<Scalars['String']['input']>;
  timestamp_utc?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSiteInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTransmitterInput = {
  frequency?: InputMaybe<Scalars['Float']['input']>;
  geometry?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  method?: InputMaybe<Scalars['String']['input']>;
  scanFrequencyTransmittersId?: InputMaybe<Scalars['ID']['input']>;
  snr?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUnifiedScanInput = {
  id: Scalars['ID']['input'];
  unifiedScanBase_scanId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateUnifiedScansConnectionInput = {
  id: Scalars['ID']['input'];
  scanId?: InputMaybe<Scalars['ID']['input']>;
  unifiedScanId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateAnalyzedEntityByIdMutationVariables = Exact<{
  updateAnalyzedEntityInput: UpdateAnalyzedEntityInput;
}>;


export type UpdateAnalyzedEntityByIdMutation = { __typename?: 'Mutation', updateAnalyzedEntity?: { __typename?: 'AnalyzedEntity', id: string, flagged?: boolean | null, frequency?: number | null, changed_by?: string | null, status?: Status | null, confidence?: Confidence | null, analysis_confidence?: Confidence | null, prefix?: string | null, z_peaks_depth_algo_view?: string | null, snr?: number | null, pipe_crossings?: string | null, n_crossings?: number | null, mean_angle_crossings?: number | null, geometry?: string | null, scan_frequency?: { __typename?: 'ScanFrequency', frequency: number, y_peaks_view?: string | null, transmitters?: { __typename?: 'ModelTransmitterConnection', items: Array<{ __typename?: 'Transmitter', geometry?: string | null, method?: string | null } | null> } | null } | null, scan?: { __typename?: 'Scan', id: string, scan_path_url?: string | null, scan_path_fix_mode_view?: string | null, scan_name: string, scan_date: any, platform_type?: string | null, site_name: string, interpolations?: { __typename?: 'ModelInterpolationConnection', items: Array<{ __typename?: 'Interpolation', interpolation_url: string, interpolation_type: string, id: string } | null> } | null } | null, comments?: { __typename?: 'ModelCommentConnection', items: Array<{ __typename?: 'Comment', id: string } | null> } | null, crossings?: { __typename?: 'ModelCrossingConnection', items: Array<{ __typename?: 'Crossing', id: string, crossing_url: string, checked?: Array<Status | null> | null } | null> } | null } | null };

export type CreateFixAnalyzedEntityTaskMutationVariables = Exact<{
  createFixAnalyzedEntityTaskInput: CreateFixAnalyzedEntityTaskInput;
}>;


export type CreateFixAnalyzedEntityTaskMutation = { __typename?: 'Mutation', createFixAnalyzedEntityTask?: { __typename?: 'FixAnalyzedEntityTask', id: string } | null };

export type CreateCommentMutationVariables = Exact<{
  createCommentsInput: CreateCommentInput;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment?: { __typename?: 'Comment', text?: string | null, id: string, changed_by: string, createdAt: any, analyzedEntityCommentsId?: string | null } | null };

export type DeleteCommentMutationVariables = Exact<{
  deleteCommentsInput: DeleteCommentInput;
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment?: { __typename?: 'Comment', id: string } | null };

export type UpdateCrossingsByIdMutationVariables = Exact<{
  updateCrossingsInput: UpdateCrossingInput;
}>;


export type UpdateCrossingsByIdMutation = { __typename?: 'Mutation', updateCrossing?: { __typename?: 'Crossing', id: string, checked?: Array<Status | null> | null } | null };

export type AnalyzedEntityByIdFieldsFragment = { __typename?: 'AnalyzedEntity', id: string, flagged?: boolean | null, frequency?: number | null, changed_by?: string | null, status?: Status | null, confidence?: Confidence | null, analysis_confidence?: Confidence | null, prefix?: string | null, z_peaks_depth_algo_view?: string | null, snr?: number | null, pipe_crossings?: string | null, n_crossings?: number | null, mean_angle_crossings?: number | null, geometry?: string | null, scan_frequency?: { __typename?: 'ScanFrequency', frequency: number, y_peaks_view?: string | null, transmitters?: { __typename?: 'ModelTransmitterConnection', items: Array<{ __typename?: 'Transmitter', geometry?: string | null, method?: string | null } | null> } | null } | null, scan?: { __typename?: 'Scan', id: string, scan_path_url?: string | null, scan_path_fix_mode_view?: string | null, scan_name: string, scan_date: any, platform_type?: string | null, site_name: string, interpolations?: { __typename?: 'ModelInterpolationConnection', items: Array<{ __typename?: 'Interpolation', interpolation_url: string, interpolation_type: string, id: string } | null> } | null } | null, comments?: { __typename?: 'ModelCommentConnection', items: Array<{ __typename?: 'Comment', id: string } | null> } | null, crossings?: { __typename?: 'ModelCrossingConnection', items: Array<{ __typename?: 'Crossing', id: string, crossing_url: string, checked?: Array<Status | null> | null } | null> } | null };

export type GetAnalyzedEntityByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetAnalyzedEntityByIdQuery = { __typename?: 'Query', getAnalyzedEntity?: { __typename?: 'AnalyzedEntity', id: string, flagged?: boolean | null, frequency?: number | null, changed_by?: string | null, status?: Status | null, confidence?: Confidence | null, analysis_confidence?: Confidence | null, prefix?: string | null, z_peaks_depth_algo_view?: string | null, snr?: number | null, pipe_crossings?: string | null, n_crossings?: number | null, mean_angle_crossings?: number | null, geometry?: string | null, scan_frequency?: { __typename?: 'ScanFrequency', frequency: number, y_peaks_view?: string | null, transmitters?: { __typename?: 'ModelTransmitterConnection', items: Array<{ __typename?: 'Transmitter', geometry?: string | null, method?: string | null } | null> } | null } | null, scan?: { __typename?: 'Scan', id: string, scan_path_url?: string | null, scan_path_fix_mode_view?: string | null, scan_name: string, scan_date: any, platform_type?: string | null, site_name: string, interpolations?: { __typename?: 'ModelInterpolationConnection', items: Array<{ __typename?: 'Interpolation', interpolation_url: string, interpolation_type: string, id: string } | null> } | null } | null, comments?: { __typename?: 'ModelCommentConnection', items: Array<{ __typename?: 'Comment', id: string } | null> } | null, crossings?: { __typename?: 'ModelCrossingConnection', items: Array<{ __typename?: 'Crossing', id: string, crossing_url: string, checked?: Array<Status | null> | null } | null> } | null } | null };

export type GetPipeDepthQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPipeDepthQuery = { __typename?: 'Query', searchAnalyzedEntities?: { __typename?: 'SearchableAnalyzedEntityConnection', items: Array<{ __typename?: 'AnalyzedEntity', depth_result_per_algorithm?: string | null, pipe_depths?: string | null } | null> } | null };

export type GetPipeDepthCheckedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPipeDepthCheckedQuery = { __typename?: 'Query', searchAnalyzedEntities?: { __typename?: 'SearchableAnalyzedEntityConnection', items: Array<{ __typename?: 'AnalyzedEntity', depth_result_per_algorithm?: string | null, pipe_depths?: string | null, crossings?: { __typename?: 'ModelCrossingConnection', items: Array<{ __typename?: 'Crossing', checked?: Array<Status | null> | null } | null> } | null } | null> } | null };

export type SearchCommentsByFilterQueryVariables = Exact<{
  filter: SearchableCommentFilterInput;
}>;


export type SearchCommentsByFilterQuery = { __typename?: 'Query', searchComments?: { __typename?: 'SearchableCommentConnection', items: Array<{ __typename?: 'Comment', analyzedEntityCommentsId?: string | null, text?: string | null, changed_by: string, id: string, createdAt: any } | null> } | null };

export type GetScansByFilterQueryVariables = Exact<{
  scanFilter: SearchableScanFilterInput;
  analyzedEntityFilter?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetScansByFilterQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', nextToken?: string | null, total?: number | null, aggregateItems: Array<{ __typename?: 'SearchableAggregateResult', name: string, result?: { __typename: 'SearchableAggregateBucketResult', buckets?: Array<{ __typename?: 'SearchableAggregateBucketResultItem', doc_count: number, key: string } | null> | null } | { __typename: 'SearchableAggregateScalarResult', value: number } | null } | null>, items: Array<{ __typename?: 'Scan', country: string, scan_date: any, scan_name: string, sensor?: string | null, site_name: string, analyzed_entities?: { __typename?: 'ModelAnalyzedEntityConnection', items: Array<{ __typename?: 'AnalyzedEntity', flagged?: boolean | null, id: string, geometry?: string | null, status?: Status | null, frequency?: number | null, confidence?: Confidence | null, scan?: { __typename?: 'Scan', id: string } | null, comments?: { __typename?: 'ModelCommentConnection', items: Array<{ __typename?: 'Comment', text?: string | null, id: string } | null> } | null } | null> } | null } | null> } | null };

export type SearchAllScansInfoQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
  siteScanId: Scalars['ID']['input'];
}>;


export type SearchAllScansInfoQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', total?: number | null, nextToken?: string | null, items: Array<{ __typename?: 'Scan', id: string, scan_name: string, scan_date: any, platform_type?: string | null, is_unified?: boolean | null, scan_frequencies?: { __typename?: 'ModelScanFrequencyConnection', items: Array<{ __typename?: 'ScanFrequency', transmitters?: { __typename?: 'ModelTransmitterConnection', items: Array<{ __typename?: 'Transmitter', method?: string | null } | null> } | null } | null> } | null, analyzed_entities?: { __typename?: 'ModelAnalyzedEntityConnection', items: Array<{ __typename?: 'AnalyzedEntity', analysis_confidence?: Confidence | null, confidence?: Confidence | null, status?: Status | null, frequency?: number | null, id: string, pipesqa_edit_is_fixed?: boolean | null, pipesqa_edit_is_original?: boolean | null } | null> } | null } | null>, aggregateItems: Array<{ __typename?: 'SearchableAggregateResult', name: string, result?: { __typename: 'SearchableAggregateBucketResult', buckets?: Array<{ __typename?: 'SearchableAggregateBucketResultItem', doc_count: number, key: string } | null> | null } | { __typename: 'SearchableAggregateScalarResult', value: number } | null } | null> } | null };

export type SearchSitePlatformTypeQueryVariables = Exact<{
  siteScanId: Scalars['ID']['input'];
}>;


export type SearchSitePlatformTypeQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', aggregateItems: Array<{ __typename?: 'SearchableAggregateResult', name: string, result?: { __typename: 'SearchableAggregateBucketResult', buckets?: Array<{ __typename?: 'SearchableAggregateBucketResultItem', doc_count: number, key: string } | null> | null } | { __typename: 'SearchableAggregateScalarResult', value: number } | null } | null> } | null };

export type SearchAllSitesInfoQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type SearchAllSitesInfoQuery = { __typename?: 'Query', searchSites?: { __typename?: 'SearchableSiteConnection', nextToken?: string | null, items: Array<{ __typename?: 'Site', id: string, name: string } | null> } | null };

export const AnalyzedEntityByIdFieldsFragmentDoc = gql`
    fragment analyzedEntityByIdFields on AnalyzedEntity {
  id
  flagged
  frequency
  changed_by
  status
  confidence
  analysis_confidence
  prefix
  z_peaks_depth_algo_view
  snr
  scan_frequency {
    frequency
    y_peaks_view
    transmitters {
      items {
        geometry
        method
      }
    }
  }
  pipe_crossings
  scan {
    id
    scan_path_url
    scan_path_fix_mode_view
    interpolations {
      items {
        interpolation_url
        interpolation_type
        id
      }
    }
    scan_name
    scan_date
    platform_type
    site_name
  }
  comments {
    items {
      id
    }
  }
  crossings {
    items {
      id
      crossing_url
      checked
    }
  }
  n_crossings
  mean_angle_crossings
  geometry
}
    `;
export const UpdateAnalyzedEntityByIdDocument = gql`
    mutation UpdateAnalyzedEntityById($updateAnalyzedEntityInput: UpdateAnalyzedEntityInput!) {
  updateAnalyzedEntity(input: $updateAnalyzedEntityInput) {
    ...analyzedEntityByIdFields
  }
}
    ${AnalyzedEntityByIdFieldsFragmentDoc}`;
export type UpdateAnalyzedEntityByIdMutationFn = Apollo.MutationFunction<UpdateAnalyzedEntityByIdMutation, UpdateAnalyzedEntityByIdMutationVariables>;

/**
 * __useUpdateAnalyzedEntityByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAnalyzedEntityByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnalyzedEntityByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnalyzedEntityByIdMutation, { data, loading, error }] = useUpdateAnalyzedEntityByIdMutation({
 *   variables: {
 *      updateAnalyzedEntityInput: // value for 'updateAnalyzedEntityInput'
 *   },
 * });
 */
export function useUpdateAnalyzedEntityByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnalyzedEntityByIdMutation, UpdateAnalyzedEntityByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnalyzedEntityByIdMutation, UpdateAnalyzedEntityByIdMutationVariables>(UpdateAnalyzedEntityByIdDocument, options);
      }
export type UpdateAnalyzedEntityByIdMutationHookResult = ReturnType<typeof useUpdateAnalyzedEntityByIdMutation>;
export type UpdateAnalyzedEntityByIdMutationResult = Apollo.MutationResult<UpdateAnalyzedEntityByIdMutation>;
export type UpdateAnalyzedEntityByIdMutationOptions = Apollo.BaseMutationOptions<UpdateAnalyzedEntityByIdMutation, UpdateAnalyzedEntityByIdMutationVariables>;
export const CreateFixAnalyzedEntityTaskDocument = gql`
    mutation CreateFixAnalyzedEntityTask($createFixAnalyzedEntityTaskInput: CreateFixAnalyzedEntityTaskInput!) {
  createFixAnalyzedEntityTask(input: $createFixAnalyzedEntityTaskInput) {
    id
  }
}
    `;
export type CreateFixAnalyzedEntityTaskMutationFn = Apollo.MutationFunction<CreateFixAnalyzedEntityTaskMutation, CreateFixAnalyzedEntityTaskMutationVariables>;

/**
 * __useCreateFixAnalyzedEntityTaskMutation__
 *
 * To run a mutation, you first call `useCreateFixAnalyzedEntityTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFixAnalyzedEntityTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFixAnalyzedEntityTaskMutation, { data, loading, error }] = useCreateFixAnalyzedEntityTaskMutation({
 *   variables: {
 *      createFixAnalyzedEntityTaskInput: // value for 'createFixAnalyzedEntityTaskInput'
 *   },
 * });
 */
export function useCreateFixAnalyzedEntityTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateFixAnalyzedEntityTaskMutation, CreateFixAnalyzedEntityTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFixAnalyzedEntityTaskMutation, CreateFixAnalyzedEntityTaskMutationVariables>(CreateFixAnalyzedEntityTaskDocument, options);
      }
export type CreateFixAnalyzedEntityTaskMutationHookResult = ReturnType<typeof useCreateFixAnalyzedEntityTaskMutation>;
export type CreateFixAnalyzedEntityTaskMutationResult = Apollo.MutationResult<CreateFixAnalyzedEntityTaskMutation>;
export type CreateFixAnalyzedEntityTaskMutationOptions = Apollo.BaseMutationOptions<CreateFixAnalyzedEntityTaskMutation, CreateFixAnalyzedEntityTaskMutationVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($createCommentsInput: CreateCommentInput!) {
  createComment(input: $createCommentsInput) {
    text
    id
    changed_by
    createdAt
    analyzedEntityCommentsId
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      createCommentsInput: // value for 'createCommentsInput'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation deleteComment($deleteCommentsInput: DeleteCommentInput!) {
  deleteComment(input: $deleteCommentsInput) {
    id
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      deleteCommentsInput: // value for 'deleteCommentsInput'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const UpdateCrossingsByIdDocument = gql`
    mutation UpdateCrossingsById($updateCrossingsInput: UpdateCrossingInput!) {
  updateCrossing(input: $updateCrossingsInput) {
    id
    checked
  }
}
    `;
export type UpdateCrossingsByIdMutationFn = Apollo.MutationFunction<UpdateCrossingsByIdMutation, UpdateCrossingsByIdMutationVariables>;

/**
 * __useUpdateCrossingsByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCrossingsByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrossingsByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrossingsByIdMutation, { data, loading, error }] = useUpdateCrossingsByIdMutation({
 *   variables: {
 *      updateCrossingsInput: // value for 'updateCrossingsInput'
 *   },
 * });
 */
export function useUpdateCrossingsByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCrossingsByIdMutation, UpdateCrossingsByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCrossingsByIdMutation, UpdateCrossingsByIdMutationVariables>(UpdateCrossingsByIdDocument, options);
      }
export type UpdateCrossingsByIdMutationHookResult = ReturnType<typeof useUpdateCrossingsByIdMutation>;
export type UpdateCrossingsByIdMutationResult = Apollo.MutationResult<UpdateCrossingsByIdMutation>;
export type UpdateCrossingsByIdMutationOptions = Apollo.BaseMutationOptions<UpdateCrossingsByIdMutation, UpdateCrossingsByIdMutationVariables>;
export const GetAnalyzedEntityByIdDocument = gql`
    query GetAnalyzedEntityById($id: ID!) {
  getAnalyzedEntity(id: $id) {
    ...analyzedEntityByIdFields
  }
}
    ${AnalyzedEntityByIdFieldsFragmentDoc}`;

/**
 * __useGetAnalyzedEntityByIdQuery__
 *
 * To run a query within a React component, call `useGetAnalyzedEntityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyzedEntityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyzedEntityByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAnalyzedEntityByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAnalyzedEntityByIdQuery, GetAnalyzedEntityByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalyzedEntityByIdQuery, GetAnalyzedEntityByIdQueryVariables>(GetAnalyzedEntityByIdDocument, options);
      }
export function useGetAnalyzedEntityByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyzedEntityByIdQuery, GetAnalyzedEntityByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalyzedEntityByIdQuery, GetAnalyzedEntityByIdQueryVariables>(GetAnalyzedEntityByIdDocument, options);
        }
export type GetAnalyzedEntityByIdQueryHookResult = ReturnType<typeof useGetAnalyzedEntityByIdQuery>;
export type GetAnalyzedEntityByIdLazyQueryHookResult = ReturnType<typeof useGetAnalyzedEntityByIdLazyQuery>;
export type GetAnalyzedEntityByIdQueryResult = Apollo.QueryResult<GetAnalyzedEntityByIdQuery, GetAnalyzedEntityByIdQueryVariables>;
export const GetPipeDepthDocument = gql`
    query GetPipeDepth($id: ID!) {
  searchAnalyzedEntities(filter: {id: {eq: $id}}) {
    items {
      depth_result_per_algorithm
      pipe_depths
    }
  }
}
    `;

/**
 * __useGetPipeDepthQuery__
 *
 * To run a query within a React component, call `useGetPipeDepthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipeDepthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipeDepthQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPipeDepthQuery(baseOptions: Apollo.QueryHookOptions<GetPipeDepthQuery, GetPipeDepthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipeDepthQuery, GetPipeDepthQueryVariables>(GetPipeDepthDocument, options);
      }
export function useGetPipeDepthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipeDepthQuery, GetPipeDepthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipeDepthQuery, GetPipeDepthQueryVariables>(GetPipeDepthDocument, options);
        }
export type GetPipeDepthQueryHookResult = ReturnType<typeof useGetPipeDepthQuery>;
export type GetPipeDepthLazyQueryHookResult = ReturnType<typeof useGetPipeDepthLazyQuery>;
export type GetPipeDepthQueryResult = Apollo.QueryResult<GetPipeDepthQuery, GetPipeDepthQueryVariables>;
export const GetPipeDepthCheckedDocument = gql`
    query GetPipeDepthChecked($id: ID!) {
  searchAnalyzedEntities(filter: {id: {eq: $id}}) {
    items {
      depth_result_per_algorithm
      pipe_depths
      crossings {
        items {
          checked
        }
      }
    }
  }
}
    `;

/**
 * __useGetPipeDepthCheckedQuery__
 *
 * To run a query within a React component, call `useGetPipeDepthCheckedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipeDepthCheckedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipeDepthCheckedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPipeDepthCheckedQuery(baseOptions: Apollo.QueryHookOptions<GetPipeDepthCheckedQuery, GetPipeDepthCheckedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPipeDepthCheckedQuery, GetPipeDepthCheckedQueryVariables>(GetPipeDepthCheckedDocument, options);
      }
export function useGetPipeDepthCheckedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPipeDepthCheckedQuery, GetPipeDepthCheckedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPipeDepthCheckedQuery, GetPipeDepthCheckedQueryVariables>(GetPipeDepthCheckedDocument, options);
        }
export type GetPipeDepthCheckedQueryHookResult = ReturnType<typeof useGetPipeDepthCheckedQuery>;
export type GetPipeDepthCheckedLazyQueryHookResult = ReturnType<typeof useGetPipeDepthCheckedLazyQuery>;
export type GetPipeDepthCheckedQueryResult = Apollo.QueryResult<GetPipeDepthCheckedQuery, GetPipeDepthCheckedQueryVariables>;
export const SearchCommentsByFilterDocument = gql`
    query SearchCommentsByFilter($filter: SearchableCommentFilterInput!) {
  searchComments(filter: $filter) {
    items {
      analyzedEntityCommentsId
      text
      changed_by
      id
      createdAt
    }
  }
}
    `;

/**
 * __useSearchCommentsByFilterQuery__
 *
 * To run a query within a React component, call `useSearchCommentsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCommentsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCommentsByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchCommentsByFilterQuery(baseOptions: Apollo.QueryHookOptions<SearchCommentsByFilterQuery, SearchCommentsByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCommentsByFilterQuery, SearchCommentsByFilterQueryVariables>(SearchCommentsByFilterDocument, options);
      }
export function useSearchCommentsByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCommentsByFilterQuery, SearchCommentsByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCommentsByFilterQuery, SearchCommentsByFilterQueryVariables>(SearchCommentsByFilterDocument, options);
        }
export type SearchCommentsByFilterQueryHookResult = ReturnType<typeof useSearchCommentsByFilterQuery>;
export type SearchCommentsByFilterLazyQueryHookResult = ReturnType<typeof useSearchCommentsByFilterLazyQuery>;
export type SearchCommentsByFilterQueryResult = Apollo.QueryResult<SearchCommentsByFilterQuery, SearchCommentsByFilterQueryVariables>;
export const GetScansByFilterDocument = gql`
    query getScansByFilter($scanFilter: SearchableScanFilterInput!, $analyzedEntityFilter: ModelAnalyzedEntityFilterInput, $nextToken: String) {
  searchScans(
    filter: $scanFilter
    aggregates: {field: country, type: terms, name: "country_name"}
    nextToken: $nextToken
  ) {
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          __typename
          value
        }
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            doc_count
            key
          }
        }
      }
    }
    items {
      country
      scan_date
      scan_name
      sensor
      site_name
      analyzed_entities(filter: $analyzedEntityFilter) {
        items {
          flagged
          id
          geometry
          status
          frequency
          confidence
          scan {
            id
          }
          comments {
            items {
              text
              id
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetScansByFilterQuery__
 *
 * To run a query within a React component, call `useGetScansByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScansByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScansByFilterQuery({
 *   variables: {
 *      scanFilter: // value for 'scanFilter'
 *      analyzedEntityFilter: // value for 'analyzedEntityFilter'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetScansByFilterQuery(baseOptions: Apollo.QueryHookOptions<GetScansByFilterQuery, GetScansByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScansByFilterQuery, GetScansByFilterQueryVariables>(GetScansByFilterDocument, options);
      }
export function useGetScansByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScansByFilterQuery, GetScansByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScansByFilterQuery, GetScansByFilterQueryVariables>(GetScansByFilterDocument, options);
        }
export type GetScansByFilterQueryHookResult = ReturnType<typeof useGetScansByFilterQuery>;
export type GetScansByFilterLazyQueryHookResult = ReturnType<typeof useGetScansByFilterLazyQuery>;
export type GetScansByFilterQueryResult = Apollo.QueryResult<GetScansByFilterQuery, GetScansByFilterQueryVariables>;
export const SearchAllScansInfoDocument = gql`
    query SearchAllScansInfo($nextToken: String, $siteScanId: ID!) {
  searchScans(
    filter: {siteScanId: {eq: $siteScanId}}
    aggregates: {field: platform_type, type: terms, name: "platforms"}
    nextToken: $nextToken
    limit: 1000
  ) {
    total
    items {
      id
      scan_name
      scan_date
      platform_type
      is_unified
      scan_frequencies(limit: 1000) {
        items {
          transmitters {
            items {
              method
            }
          }
        }
      }
      analyzed_entities(limit: 1000) {
        items {
          analysis_confidence
          confidence
          status
          frequency
          id
          pipesqa_edit_is_fixed
          pipesqa_edit_is_original
        }
      }
    }
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          __typename
          value
        }
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            doc_count
            key
          }
        }
      }
    }
    nextToken
  }
}
    `;

/**
 * __useSearchAllScansInfoQuery__
 *
 * To run a query within a React component, call `useSearchAllScansInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAllScansInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAllScansInfoQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      siteScanId: // value for 'siteScanId'
 *   },
 * });
 */
export function useSearchAllScansInfoQuery(baseOptions: Apollo.QueryHookOptions<SearchAllScansInfoQuery, SearchAllScansInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAllScansInfoQuery, SearchAllScansInfoQueryVariables>(SearchAllScansInfoDocument, options);
      }
export function useSearchAllScansInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAllScansInfoQuery, SearchAllScansInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAllScansInfoQuery, SearchAllScansInfoQueryVariables>(SearchAllScansInfoDocument, options);
        }
export type SearchAllScansInfoQueryHookResult = ReturnType<typeof useSearchAllScansInfoQuery>;
export type SearchAllScansInfoLazyQueryHookResult = ReturnType<typeof useSearchAllScansInfoLazyQuery>;
export type SearchAllScansInfoQueryResult = Apollo.QueryResult<SearchAllScansInfoQuery, SearchAllScansInfoQueryVariables>;
export const SearchSitePlatformTypeDocument = gql`
    query SearchSitePlatformType($siteScanId: ID!) {
  searchScans(
    filter: {siteScanId: {eq: $siteScanId}}
    aggregates: {field: platform_type, type: terms, name: "platforms"}
  ) {
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          __typename
          value
        }
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            doc_count
            key
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSearchSitePlatformTypeQuery__
 *
 * To run a query within a React component, call `useSearchSitePlatformTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSitePlatformTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSitePlatformTypeQuery({
 *   variables: {
 *      siteScanId: // value for 'siteScanId'
 *   },
 * });
 */
export function useSearchSitePlatformTypeQuery(baseOptions: Apollo.QueryHookOptions<SearchSitePlatformTypeQuery, SearchSitePlatformTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSitePlatformTypeQuery, SearchSitePlatformTypeQueryVariables>(SearchSitePlatformTypeDocument, options);
      }
export function useSearchSitePlatformTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSitePlatformTypeQuery, SearchSitePlatformTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSitePlatformTypeQuery, SearchSitePlatformTypeQueryVariables>(SearchSitePlatformTypeDocument, options);
        }
export type SearchSitePlatformTypeQueryHookResult = ReturnType<typeof useSearchSitePlatformTypeQuery>;
export type SearchSitePlatformTypeLazyQueryHookResult = ReturnType<typeof useSearchSitePlatformTypeLazyQuery>;
export type SearchSitePlatformTypeQueryResult = Apollo.QueryResult<SearchSitePlatformTypeQuery, SearchSitePlatformTypeQueryVariables>;
export const SearchAllSitesInfoDocument = gql`
    query SearchAllSitesInfo($nextToken: String) {
  searchSites(nextToken: $nextToken, limit: 1000) {
    items {
      id
      name
    }
    nextToken
  }
}
    `;

/**
 * __useSearchAllSitesInfoQuery__
 *
 * To run a query within a React component, call `useSearchAllSitesInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAllSitesInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAllSitesInfoQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useSearchAllSitesInfoQuery(baseOptions?: Apollo.QueryHookOptions<SearchAllSitesInfoQuery, SearchAllSitesInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAllSitesInfoQuery, SearchAllSitesInfoQueryVariables>(SearchAllSitesInfoDocument, options);
      }
export function useSearchAllSitesInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAllSitesInfoQuery, SearchAllSitesInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAllSitesInfoQuery, SearchAllSitesInfoQueryVariables>(SearchAllSitesInfoDocument, options);
        }
export type SearchAllSitesInfoQueryHookResult = ReturnType<typeof useSearchAllSitesInfoQuery>;
export type SearchAllSitesInfoLazyQueryHookResult = ReturnType<typeof useSearchAllSitesInfoLazyQuery>;
export type SearchAllSitesInfoQueryResult = Apollo.QueryResult<SearchAllSitesInfoQuery, SearchAllSitesInfoQueryVariables>;