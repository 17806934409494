import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { Status } from '@graphql/generated';

type Props = {
  selectedCrossing: number | null;
  setSelectedCrossing: (value: number | null) => void;
  loading: boolean;
  handleUpdateStatus: (status: Status) => void;
};

const Modal = ({ selectedCrossing, setSelectedCrossing, loading, handleUpdateStatus }: Props) => {
  const modalId = document.getElementById('modal') as HTMLElement;
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setSelectedCrossing(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setSelectedCrossing]);

  if (selectedCrossing === null) return null;

  return createPortal(
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1001
      }}>
      <div
        ref={modalRef}
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          width: '300px',
          position: 'relative'
        }}>
        <h3 style={{ margin: '0 0 16px 0' }}>Update Crossing {selectedCrossing}</h3>
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <button
            onClick={() => handleUpdateStatus(Status.Approved)}
            disabled={loading}
            style={{
              padding: '8px 16px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              cursor: loading ? 'not-allowed' : 'pointer'
            }}>
            Approve
          </button>
          <button
            onClick={() => handleUpdateStatus(Status.Rejected)}
            disabled={loading}
            style={{
              padding: '8px 16px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              cursor: loading ? 'not-allowed' : 'pointer'
            }}>
            Reject
          </button>
        </div>
        <button
          onClick={() => setSelectedCrossing(null)}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            fontSize: '18px'
          }}>
          ✕
        </button>
      </div>
    </div>,
    modalId
  );
};

export default Modal;
