import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  flex: 1;
  overflow: auto;
  height: 100%;
`;
export const Container = styled.div`
  display: flex;
  gap: 24px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 12px;
  box-sizing: border-box;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
  height: 190px;
`;

export const Slider = styled.input`
  writing-mode: vertical-lr;
  direction: rtl;
  vertical-align: middle;
  height: 100px;
`;

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  gap: 10px;
  padding: 2px 0;
`;

export const Hr = styled.hr`
  margin: 8px 0;
  border-color: rgba(0, 0, 0, 0.1);
`;

export const Divider = styled.div`
  height: 100%;
  width: 0.5px;
  min-width: 0.5px;
  background: rgba(0, 0, 0, 0.2);
`;

export const SlidersContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
`;

export const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
