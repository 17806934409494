import styled from 'styled-components';

export const StatusButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Icon = styled.img``;
