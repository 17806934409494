import React, { useState, useContext, useMemo } from 'react';
import { useCrossingViewStore } from '@store';
import L, { LatLngExpression } from 'leaflet';
import { Marker } from 'react-leaflet';
import { useUpdateCrossingsByIdMutation, Status } from '@graphql/generated';

import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';

import Modal from './Modal';

type PipeCrossingsProps = {
  data: Array<{
    lon: number;
    lat: number;
    crossing_index: number;
  }>;
};

export const PipeCrossings = ({ data }: PipeCrossingsProps) => {
  const { analyzedEntity } = useContext(AnalyzedEntityContext);
  const crossingId = analyzedEntity?.crossings?.items[0]?.id || '';
  const setCrossingStatus = useCrossingViewStore((s) => s.setCrossingStatus);
  const crossingViewIndex = useCrossingViewStore((s) => s.crossingViewIndex);
  const [updateCrossingsById, { loading }] = useUpdateCrossingsByIdMutation();
  const [selectedCrossing, setSelectedCrossing] = useState<number | null>(null);

  const PipeCrossings = analyzedEntity?.pipe_crossings
    ? JSON.parse(analyzedEntity.pipe_crossings)
    : [];

  const statusItems = useMemo(() => {
    const checkedItems = analyzedEntity?.crossings?.items[0]?.checked || [];

    if (PipeCrossings.length > checkedItems.length) {
      const missingItemsCount = PipeCrossings.length - checkedItems.length;
      const pendingItems = Array(missingItemsCount).fill(Status.Pending);

      return [...checkedItems, ...pendingItems];
    }

    return checkedItems;
  }, [analyzedEntity?.id, PipeCrossings.length]);

  const handleMarkerClick = (crossing_index: number) => setSelectedCrossing(crossing_index);

  const handleUpdateStatus = async (status: Status) => {
    if (!crossingId || selectedCrossing === null) return;

    const arr = statusItems.map((item, index) => {
      if (index === selectedCrossing) return status;
      else return item;
    });

    await updateCrossingsById({
      variables: {
        updateCrossingsInput: {
          id: crossingId,
          checked: arr
        }
      }
    }).then(() => {
      setCrossingStatus(crossingId, arr);
      setSelectedCrossing(null);
    });
  };

  return (
    <>
      {data.map(({ lat, lon, crossing_index }) => {
        if (!lat || !lon) return null;

        const position: LatLngExpression = [lat, lon];
        const size = 20;
        const isActive = crossing_index === crossingViewIndex;

        const icon = L.divIcon({
          className: '',
          html: `<div style="width: ${size}px; height: ${size}px; background-color: ${
            isActive ? '#000' : 'red'
          }; border-radius: 50%; display: flex; align-items: center; justify-content: center; color: white; font-size: ${12}px; cursor: pointer;">${crossing_index}</div>`,
          iconSize: [size, size]
        });

        return (
          <React.Fragment key={crossing_index}>
            <Marker
              position={position}
              icon={icon}
              eventHandlers={{
                click: () => handleMarkerClick(crossing_index)
              }}
            />
          </React.Fragment>
        );
      })}
      <Modal
        selectedCrossing={selectedCrossing}
        setSelectedCrossing={setSelectedCrossing}
        loading={loading}
        handleUpdateStatus={handleUpdateStatus}
      />
    </>
  );
};
