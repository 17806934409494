import { create } from 'zustand';

const MIN_SLIDER_VALUE = 0;
const MAX_SLIDER_VALUE = 100;

type MapControlsStore = {
  maxZoom: number;
  minZoom: number;
  setMaxZoom: (value: number) => void;
  setMinZoom: (value: number) => void;
  interpolation: boolean;
  toggleInterpolation: VoidFunction;
  interpolationOpacity: number;
  setInterpolationOpacity: (value: number) => void;
  currentPipe: boolean;
  toggleCurrentPipe: VoidFunction;
};

export const useMapControlsStore = create<MapControlsStore>((set) => ({
  maxZoom: MAX_SLIDER_VALUE,
  minZoom: MIN_SLIDER_VALUE,
  interpolation: true,
  interpolationOpacity: 100,
  currentPipe: true,
  setMaxZoom: (value: number) => set(() => ({ maxZoom: value })),
  setMinZoom: (value: number) => set(() => ({ minZoom: value })),
  toggleInterpolation: () => set((state) => ({ interpolation: !state.interpolation })),
  setInterpolationOpacity: (value: number) => set(() => ({ interpolationOpacity: value })),
  toggleCurrentPipe: () => set((state) => ({ currentPipe: !state.currentPipe }))
}));
