import L from 'leaflet';

import type { LineData } from './EditPipe.types';

export const formatCoordinate = (num: number): number => {
  return Number(num.toFixed(9));
};

// Function to calculate distance from point to line segment
export const distanceToLineSegment = (point: L.LatLng, start: L.LatLng, end: L.LatLng) => {
  const startToPoint = point.distanceTo(start);
  const endToPoint = point.distanceTo(end);
  const length = start.distanceTo(end);

  // If the line has zero length, return distance to either point
  if (length === 0) return startToPoint;

  // Calculate the relative position of the projection
  const t =
    ((point?.lng - start?.lng) * (end?.lng - start?.lng) +
      (point?.lat - start?.lat) * (end?.lat - start?.lat)) /
    (Math.pow(end?.lng - start?.lng, 2) + Math.pow(end?.lat - start?.lat, 2));

  // If projection falls outside the line segment, return distance to nearest endpoint
  if (t <= 0) return startToPoint;
  if (t >= 1) return endToPoint;

  // Calculate the projected point
  const projectedPoint = L.latLng(
    start?.lat + t * (end?.lat - start?.lat),
    start?.lng + t * (end?.lng - start?.lng)
  );

  return point.distanceTo(projectedPoint);
};

export const formatPipeDataForApi = (lines: LineData[]) => {
  return lines.map((line) => ({
    id: line.id,
    type: line.data.type,
    coordinates: line.data.coordinates
  }));
};
