import { CSSProperties } from 'react';
import { AlertCircle } from 'lucide-react';

const styles: Record<string, CSSProperties> = {
  container: {
    backgroundColor: '#f8f9fa',
    borderRadius: 4,
    padding: 8,
    marginBottom: 8,
    fontSize: 11,
    height: 130,
    width: 780
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    marginBottom: 6,
    color: '#4a5568',
    height: 20
  },
  headerText: {
    fontWeight: 500,
    fontSize: 11
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 'calc(100% - 26px)',
    margin: '0 4px'
  },
  section: {
    flex: 1,
    padding: '0 8px',
    borderRight: '1px solid #e2e8f0',
    height: '100%',
    margin: '0 4px'
  },
  lastSection: {
    flex: 1,
    padding: '0 8px',
    height: '100%'
  },
  sectionTitle: {
    fontWeight: 500,
    marginBottom: 4,
    color: '#4a5568',
    fontSize: 11
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    color: '#4a5568'
  },
  listItem: {
    marginBottom: 3,
    display: 'flex',
    alignItems: 'flex-start',
    gap: 4,
    fontSize: 10
  },
  bullet: {
    fontSize: 7,
    marginRight: 2,
    lineHeight: '14px'
  },
  listText: {
    lineHeight: '14px'
  }
};

const EditModeInstructions = () => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <AlertCircle size={16} />
        <span style={styles.headerText}>Available Edit Actions:</span>
      </div>
      <div style={styles.content}>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Edit Points</h3>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <span style={styles.bullet}>•</span>
              <span style={styles.listText}>Drag markers to adjust positions</span>
            </li>
            <li style={styles.listItem}>
              <span style={styles.bullet}>•</span>
              Right-click marker to delete
            </li>
            <li style={styles.listItem}>
              <span style={styles.bullet}>•</span>
              Left-click line to add point
            </li>
          </ul>
        </div>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Edit Line Segments</h3>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <span style={styles.bullet}>•</span>
              Right-click segment to delete
            </li>
            <li style={styles.listItem}>
              <span style={styles.bullet}>•</span>
              Middle segment delete splits line
            </li>
            <li style={styles.listItem}>
              <span style={styles.bullet}>•</span>
              Shift+Click to straighten line
            </li>
          </ul>
        </div>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Line Movement</h3>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <span style={styles.bullet}>•</span>
              <span style={styles.listText}>Hold D key to enter line drag mode</span>
            </li>
            <li style={styles.listItem}>
              <span style={styles.bullet}>•</span>
              <span style={styles.listText}>Click and drag line to move entire segment</span>
            </li>
          </ul>
        </div>
        <div style={styles.lastSection}>
          <h3 style={styles.sectionTitle}>Undo/Redo</h3>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <span style={styles.bullet}>•</span>
              Ctrl+Z to undo last action
            </li>
            <li style={styles.listItem}>
              <span style={styles.bullet}>•</span>
              Ctrl+Shift+Z to redo
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EditModeInstructions;
