import { create } from 'zustand';

import type { LineData } from '@components/Map/EditPipe/EditPipe.types';

type EditPipeStore = {
  lines: LineData[];
  setLines: (lines: LineData[]) => void;
  editMode: boolean;
  toggleEditMode: VoidFunction;
  setEditMode: (value: boolean) => void;
};

export const useEditPipeStore = create<EditPipeStore>((set) => ({
  lines: [],
  setLines: (lines) => set({ lines }),
  editMode: false,
  toggleEditMode: () => set((state) => ({ editMode: !state.editMode })),
  setEditMode: (value) => set({ editMode: value })
}));
