import React, { useContext } from 'react';
import {
  SubTitleText,
  TitleContainer,
  TitleText,
  StyledButton,
  HeaderContainer,
  NameContainer,
  Divider,
  IDTitle,
  ButtonContainer,
  EditModeButton
} from './styles';
import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import { useEditPipeStore } from '@store';

interface Props {
  onClickCenter: () => void;
}

const Header: React.FC<Props> = (props) => {
  const { onClickCenter } = props;

  const { toggleEditMode, editMode } = useEditPipeStore();
  const { analyzedEntity } = useContext(AnalyzedEntityContext);

  return (
    <HeaderContainer>
      <TitleContainer>
        <NameContainer>
          <TitleText>{analyzedEntity?.scan?.scan_date}</TitleText>
          <Divider />
          <TitleText>{analyzedEntity?.scan?.site_name}</TitleText>
          <Divider />
          <SubTitleText>{analyzedEntity?.scan?.scan_name}</SubTitleText>
        </NameContainer>
        <ButtonContainer>
          <EditModeButton editmode={editMode ? 'true' : 'false'} onClick={() => toggleEditMode()}>
            Edit Mode
          </EditModeButton>
          <StyledButton onClick={onClickCenter}>Center</StyledButton>
        </ButtonContainer>
      </TitleContainer>
      <IDTitle>UUID: {analyzedEntity?.id}</IDTitle>
    </HeaderContainer>
  );
};

export default Header;
