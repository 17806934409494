import Control from 'react-leaflet-custom-control';

import { CrossingIndexPolygonButton } from '../Polygon/CrossingIndexPolyginButton';

import { useEditPipeStore } from '@store';

export const MapControls = () => {
  const editMode = useEditPipeStore((state) => state.editMode);

  return <Control position="topleft">{!editMode && <CrossingIndexPolygonButton />}</Control>;
};
